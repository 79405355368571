import React, { useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Store } from '../../Store';
import { useLaxElement } from 'use-lax';

import './WorkSingleCollaborate.scss';

export default function WorkSingleCollaborate() {
    const { state, dispatch } = useContext(Store);
    const { fullScreenHeight } = state;

    const sectionRef = useRef();

    const textRef = useLaxElement();
    const buttonRef = useLaxElement();

    useEffect(() => {
        function sectionHeaderColor() {
            if (sectionRef.current && window.scrollY >= sectionRef.current.offsetTop && window.scrollY < sectionRef.current.offsetTop + sectionRef.current.clientHeight) {
                dispatch({ type: 'SET_WHITE_HEADER', payload: true });
            }
        }

        window.addEventListener('scroll', sectionHeaderColor);

        return (() => {
            window.removeEventListener('scroll', sectionHeaderColor);
        });
    }, [sectionRef, dispatch]);

    return (
        <div ref={sectionRef} className="work-collaborate full-screen center-section" style={{ minHeight: fullScreenHeight }}>
            <div className="work-collaborate-inner container container-padding">
                <div ref={textRef} className="work-collaborate-text" data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">Want to <br />Collaborate?</div>
                <div ref={buttonRef} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1"><Link to="/contact/" className="plus-btn white"><div>Contact Us</div><div className="icon"></div></Link></div>
            </div>
        </div>
    );
}
