import React, { useEffect, useContext, useState } from 'react';
import { Route, Link } from 'react-router-dom';
import { Store } from '../../Store';
import { CSSTransition } from 'react-transition-group';
import AnimateCC from 'react-adobe-animate';
import Script from 'react-load-script';
import useAxios from 'axios-hooks';
import { useLaxElement } from 'use-lax';
import axios from 'axios';

import api from '../../api/api';

import './ServiceSingle.scss';

function ServiceSingleDetails({ service, setActiveBackgroundColor, setActiveServiceId }) {
    const [ animationLoaded, setAnimationLoaded ] = useState(false);

    function getSeo() {
        axios.get('https://phpstack-238280-4410839.cloudwaysapps.com/admin/wp-json/yoast/v1/get_head?url=' + window.location.href).then(res => {
            const seo = res.data;

            if (seo) {
                document.title = seo.json.og_title ? seo.json.og_title : 'Work - Creative Monster';
                document.querySelector('meta[name="description"]').setAttribute("content", seo.json.og_description ? seo.json.og_description : '');
            }
        }).catch(function(e) {

        })
    }

    useEffect(() => {
        getSeo();
        setActiveBackgroundColor(service.backgroundColor);
        setActiveServiceId(service.id);
    }, [service, setActiveBackgroundColor, setActiveServiceId]);

    return (
        <article className="service-details">
            <div>
                <h1 className="header header-md">{ service.header ? service.header : service.title }</h1>
                { service.header ? <h2 className="sub-header">{service.title}</h2> : null }
                <div className="content">
                    <div className="text-wrap">
                        <div dangerouslySetInnerHTML={{ __html: service.content }} className="text"></div>
                        <div className="btns">
                            { service.workLink ? <Link to={service.workLink} className="plus-btn white"><div>Our Work</div><div className="icon"></div></Link> : null }
                            <Link to="/contact/" className="plus-btn white"><div>Contact Us</div><div className="icon"></div></Link>
                        </div>
                    </div>
                    <div className="animation-wrap">
                        { service.animationName && service.animationScript && animationLoaded ? <AnimateCC animationName={service.animationName} /> : null }
                    </div>
                </div>
            </div>

            { service.animationName && service.animationScript ?
                <Script
                url={service.animationScript}
                onLoad={() => { if (!animationLoaded) setAnimationLoaded(true) }} />
            : null }
        </article>
    );
}

function ShowAnimationVideos(props) {
    const videoRef = useLaxElement();
    const videosRef = useLaxElement();

    return (
        <div>
            { props.data.acf.featured_video.url ? 

                <div ref={videoRef} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1" className="service-featured-video"
                    style={{ 
                        backgroundImage: `url(${ props.data.acf.featured_video.background_image.url })`, 
                    }}
                >
                    <div className="featured-content container container-padding">
                            <p>Latest Video</p>
                            <h2 className="header-sm">{ props.data.acf.featured_video.title }</h2>
                            <p>{ props.data.acf.featured_video.text }</p>
                            <a className="plus-btn white" data-lity href={ props.data.acf.featured_video.url }><div>Play</div><div className="icon"></div></a>
                        </div>
                    </div>


            : null }

            <div className="service-videos-container">
                <div className="container container-padding">
                    <div ref={videosRef} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1" className="service-videos">
                        
                        {/*<pre>{ JSON.stringify(props.data.acf.featured_video, null, 2) }</pre>*/}

                        { props.data.acf.videos.map((video, index) =>

                            <a className="service-video" data-lity href={video.video_url} key={index}>
                                <div className="video-hover">
                                    <img src={ video.video_image.url } 
                                         alt={ video.video_image.alt } 
                                         className="video-image" />
                                    <div className="plus-btn white">
                                        <div>Play</div><div className="icon"></div>
                                    </div>
                                </div>
                                <h3>{ video.video_title }</h3>
                                <p>{ video.video_text }</p>
                            </a>
                        ) }

                    </div>

                    <div className="text-center">
                        <Link to="/contact/" className="plus-btn white">
                            <div>Contact us</div><div className="icon"></div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>   
    )
}

function ShowFilmVideos(props) {
    const videoRef = useLaxElement();
    const videosRef = useLaxElement();

    return (
        <div>
            { props.data.acf.featured_video_film ? 

                <div ref={videoRef} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1" className="service-featured-video"
                    style={{ 
                        backgroundImage: `url(${ props.data.acf.featured_video_film.background_image.url })`,
                        display: props.data.acf.featured_video_film.background_image.url ? "block" : "none"
                    }}
                >
                    <div className="featured-content container container-padding">
                            <p>Latest Video</p>
                            <h2 className="header-sm">{ props.data.acf.featured_video_film.title }</h2>
                            <p>{ props.data.acf.featured_video_film.text }</p>
                            <a className="plus-btn white" data-lity href={ props.data.acf.featured_video_film.url }><div>Play</div><div className="icon"></div></a>
                        </div>
                    </div>


            : null }

            { props.data.acf.featured_video_film ? 

                <div className="service-videos-container film-videos" style={{ display: props.data.acf.videos_film[0].video_title ? "block" : "none"}}>
                    <div className="container container-padding">
                        <div ref={videosRef} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1" className="service-videos">
                            
                            {/*<pre>{ JSON.stringify(props.data.acf.featured_video, null, 2) }</pre>*/}

                            { props.data.acf.videos_film.map((video, index) =>

                                <a className="service-video" data-lity href={video.video_url} key={index}>
                                    <div className="video-hover">
                                        <img src={ video.video_image.url } 
                                             alt={ video.video_image.alt } 
                                             className="video-image" />
                                        <div className="plus-btn white">
                                            <div>Play</div><div className="icon"></div>
                                        </div>
                                    </div>
                                    <h3>{ video.video_title }</h3>
                                    <p>{ video.video_text }</p>
                                </a>
                            ) }

                        </div>

                        <div className="text-center">
                            <Link to="/contact/" className="plus-btn white">
                                <div>Contact us</div><div className="icon"></div>
                            </Link>
                        </div>
                    </div>
                </div>

            : null }
        </div> 
    )
}

export default function ServiceSingle() {
    const { state, dispatch } = useContext(Store);
    const { services, smFullScreenHeight } = state;
    const [ activeServiceId, setActiveServiceId ] = useState();
    const [ activeBackgroundColor, setActiveBackgroundColor ] = useState('#ffffff');
    const [{ data }] = useAxios(api.options);

    useEffect(() => {
        dispatch({ type: 'SET_WHITE_HEADER', payload: true });
        
        return () => dispatch({ type: 'SET_WHITE_HEADER', payload: false });
    }, [dispatch]);

    const serviceLinks = [];
    
    services.forEach(service => {
        if (service.parent) {
            if (!serviceLinks[service.parent]) {
                serviceLinks[service.parent] = {};
            }

            if (!serviceLinks[service.parent].subServices) {
                serviceLinks[service.parent].subServices = [];
            }

            serviceLinks[service.parent].subServices[service.id] = { title: service.title, path: service.path };
        } else {
            if (!serviceLinks[service.id]) {
                serviceLinks[service.id] = { title: service.title, path: service.path, subServices: [] };
            } else {
                serviceLinks[service.id].title = service.title;
                serviceLinks[service.id].path = service.path;
                serviceLinks[service.id].subServices = [];
            }
        }
    });

    return (
        <div>

            <main className="service-content" style={{ backgroundColor: activeBackgroundColor }}>
                <div className="service-inner container container-padding">
                    { serviceLinks.length > 1 ? 
                        <nav className="service-nav sm-full-screen center-section" style={{ minHeight: smFullScreenHeight }}>
                            <div className="service-nav-inner">
                                { serviceLinks.map(({ path, title, subServices }, index) =>
                                    <div key={index} className="service-nav-item">
                                        <Link to={path} className={`${index === activeServiceId || subServices[activeServiceId] ? 'active' : ''} ${ subServices.length ? 'has-sub-nav' : null }`}>
                                            { subServices.length ? <div className="icon"></div> : null }
                                            <span>{title}</span>
                                        </Link>

                                        { (index === activeServiceId || subServices[activeServiceId]) && subServices.length ? 
                                            <div className="sub-service-nav">
                                                { subServices.map(({ path, title }, index) =>
                                                    <div key={index} className="sub-service-nav-item">
                                                        <Link to={path} className={ index === activeServiceId ? 'active' : '' }>{title}</Link>
                                                    </div>
                                                ) }
                                            </div>
                                        : null }
                                    </div>
                                ) }
                            </div>
                        </nav>
                    : null }

                    <div className="service sm-full-screen center-section" style={{ minHeight: smFullScreenHeight }}>
                        { services && services.length ? services.map(service =>
                            <Route key={service.id} path={service.path} exact>
                                <CSSTransition in={true} appear={true} timeout={{ enter: 500 }} classNames="fade">
                                    <ServiceSingleDetails service={service} setActiveBackgroundColor={setActiveBackgroundColor} setActiveServiceId={setActiveServiceId} />
                                </CSSTransition>
                            </Route>
                        ) : null}
                    </div>
                </div>

                { (activeServiceId === 4 || activeServiceId === 3) && data ? 
                    <div className="scroller-wrapper">
                        <div>
                            <div className="scroller">
                                <span></span>
                            </div>
                            <div className="scroller-text">SCROLL</div>
                        </div>
                    </div>
                : null }

            </main>

            { activeServiceId === 4 && data ? 

                <ShowAnimationVideos data={data} />

            : null }

            { activeServiceId === 3 && data ? 

                <ShowFilmVideos data={data} />

            : null }

        </div>
    );
}
