import React, { useContext, useState } from 'react';
import AnimateCC from 'react-adobe-animate';
import { Store } from '../../Store';
import Script from 'react-load-script';

import './Error404.scss';

export default function Error404() {
    const { state } = useContext(Store);
    const { fullScreenHeight } = state;

    const [ monster404Loaded, setMonster404Loaded ] = useState(false);

    return (
        <div className="error-404-content full-screen center-section" style={{ minHeight: fullScreenHeight }}>
            <h1 className="container container-padding header header-sm">Oops! Well this is awkward.</h1>
            <div className="md-container container-padding">
                <p>Unfortunately you have found our elusive 404 error page, which means the page you’re looking for is no longer here.</p>
            </div>

            <div className="error-404-animation animation-wrap">
                { monster404Loaded ? <AnimateCC animationName="_404" /> : null }
            </div>

            <Script
            url="/monster-404-animation.js"
            onLoad={() => { if (!monster404Loaded) setMonster404Loaded(true) }} />
        </div>
    );
}
