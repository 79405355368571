import React, { useContext, useState, useEffect } from 'react';
import { Store } from '../../Store';
// import ScrollableAnchor from 'react-scrollable-anchor';
import AnimateCC from 'react-adobe-animate';
import Script from 'react-load-script';

import MessageForm from '../ContactForm/ContactForm';

import axios from 'axios';

import './Contact.scss';

export default function Contact() {
    const { dispatch } = useContext(Store);

    const [skateboardMonsterLoaded, setSkateboardMonsterLoaded] = useState(false);

    function getSeo() {
        axios.get('https://phpstack-238280-4410839.cloudwaysapps.com/admin/wp-json/yoast/v1/get_head?url=' + window.location.href).then(res => {
            const seo = res.data;

            if (seo) {
                document.title = seo.json.og_title ? seo.json.og_title : 'Contact - Creative Monster';
                document.querySelector('meta[name="description"]').setAttribute("content", seo.json.og_description ? seo.json.og_description : '');
            }
        }).catch(function(e) {

        })
    }

    useEffect(() => {
        getSeo();

        dispatch({ type: 'SET_WHITE_HEADER', payload: true });
        
        return () => dispatch({ type: 'SET_WHITE_HEADER', payload: false });
        // eslint-disable-next-line
    }, []);

    return (
        <main className="contact-content">
            
            <div className="contact">
                <div className="container container-padding">
                    <h1 className="contact-header header header-lg">Get In Touch</h1>

                    <div className="contact-details-wrap">
                        <div className="contact-details">
                            <MessageForm />
                        </div>
                        <div className="contact-animation animation-wrap">
                            { skateboardMonsterLoaded ? <AnimateCC animationName="SkateboardMonster" /> : null }
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-info">
                <div className="container container-padding">
                    <h2 className="header header-lg">Contact</h2>

                    <div className="info-row">
                        <div className="col">
                            <h2>Telephone</h2>
                            01483 374 643
                        </div>
                        <div className="col">
                            <h2>Email</h2>
                            <a href="mailto:hi5@creativemonster.co.uk">hi5@creativemonster.co.uk</a>
                        </div>
                        <div className="col">
                            <h2>Address</h2>
                                Monstropolis! <br />
                                Tannery Studios, <br />
                                Unit C Tannery Lane, <br />
                                Woking, <br />
                                Surrey, GU23 7EF. 
                            <div className="google-maps-link">
                                <a href="https://g.page/creative-monster" target="_blank" rel="noopener noreferrer"><u><strong>Google maps</strong></u></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

{/*            <div className="contact">
                <div className="md-container container-padding">
                    <h1 className="header header-lg">Contact</h1>

                    <div className="contact-details-wrap">
                        <div className="contact-details">
                            <div>
                                Telephone <br />
                                <strong>01483 374 643</strong>
                            </div>
                            <div>
                                Email <br />
                                <a href="mailto:hi5@creativemonster.co.uk"><strong>hi5@creativemonster.co.uk</strong></a>
                            </div>
                            <div>
                                Address <br />
                                <strong>
                                    Tate House <br />
                                    Watermark Way, <br />
                                    Hertford, SG13 7TZ.
                                </strong>
                            </div>
                            <div className="google-maps-link">
                                <a href="https://g.page/bluesquarebrand" target="_blank" rel="noopener noreferrer"><strong>Google maps</strong></a>
                            </div>
                        </div>
                        <div className="contact-animation animation-wrap">
                            { skateboardMonsterLoaded ? <AnimateCC animationName="SkateboardMonster" /> : null }
                        </div>
                    </div>
                </div>
            </div>*/}

{/*            // <ScrollableAnchor id="message">
            //     <div>
            //         <div className="contact-send-message">
            //             <div className="md-container container-padding">
            //                 <a href="#message" className="plus-btn white">
            //                     Send us a message
            //                     <div className="icon"></div>
            //                 </a>
            //             </div>
            //         </div>

            //         <MessageForm />
            //     </div>
            // </ScrollableAnchor>*/}
            
            <Script
            url="/skateboard-monster-animation.js"
            onLoad={() => { if (!skateboardMonsterLoaded) setSkateboardMonsterLoaded(true) }} />
        </main>
    )
}
