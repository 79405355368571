import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { scrollToggle } from '../../Helpers';
import { Store } from '../../Store';
import SiteNav from '../SiteNav/SiteNav';
import useAxios from 'axios-hooks';
import Cookies from 'universal-cookie';

import api from '../../api/api';

import './SiteHeader.scss';

import logoDark from '../../assets/images/logo-dark.svg';
import logoWhite from '../../assets/images/logo-white.svg';

export default function SiteHeader() {
    const { state } = useContext(Store);
    const { whiteHeader } = state;
    const [ navActive, setNavActive ] = useState(false);
    const [{ data }] = useAxios(api.options);
    const cookies = new Cookies();
    const seenPromo = cookies.get('seenPromo');

    function toggleNav() {
        if (navActive) {
            scrollToggle(true);
        } else {
            scrollToggle();
        }

        setNavActive(!navActive);
    }

    function closeNav() {
        setNavActive(false);
        scrollToggle(true);
    }

    function togglePromo() {
        // console.log('test');

        let promo = document.querySelector(".promo-sidebar");
        let promoFade = document.querySelector(".promo-fade");

        if (promo && promo.classList.contains('open')) {
            promoFade.classList.remove('show');
            
            setTimeout(function() {
                promo.classList.remove("open");
            }, 600);

            cookies.set('seenPromo', true, { path: '/' });

        } else if (promo && !seenPromo) {
            promo.classList.add("open");

            setTimeout(function() {
                promoFade.classList.add("show");
            }, 600);
        }
    }

    useEffect(() => {
       const timer = setTimeout(function() {
            togglePromo();
        }, 3000);

       return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, []);
    
    return (
        <div>

        <header id="site-header" className={`fixed-el ${whiteHeader ? 'white-header' : ''} ${navActive ? 'show-site-nav' : ''}`}>
            <div id="site-header-inner" className="container container-padding-md">
                <div id="site-logo">
                    <Link to="/" onClick={closeNav}>
                        <img src={logoDark} alt="Creative Monster" id="site-logo-dark" />
                        <img src={logoWhite} alt="Creative Monster" id="site-logo-white" />
                    </Link>
                </div>

                <div>
                    <Link to="/contact/" className={"site-header-contact-link plus-btn" + (whiteHeader ? ' white' : '') }>
                        <div>Contact</div><div className="icon"></div>
                    </Link>

                    <button type="button" id="site-nav-btn" className={navActive ? 'close' : ''} onClick={toggleNav}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </button>
                </div>
            </div>

            <SiteNav closeNav={closeNav} />

        </header>

        {/*<pre>{ JSON.stringify(data, null, 2) }</pre>*/}

        { data && data.acf.promo.show_promo ?

            <div className="promo-sidebar">
                <div className="promo-fade">
                    <div className="promo-close" onClick={ () => { togglePromo() } }>
                        <div className="icon"></div>
                        <div className="text">Close</div>
                    </div>

                    <div className="promo-inner">
                        <p className="promo-pre-header">{ data.acf.promo.pre_heading.replace(/(<([^>]+)>)/gi, "") }</p>
                        <h2 className="header-sm">{ data.acf.promo.heading }</h2>

                        { data.acf.promo.image.url ? 
                            <img src={ data.acf.promo.image.url } 
                            alt={ data.acf.promo.image.alt } 
                            className="promo-image" /> 
                        : null }

                        <p>{ data.acf.promo.text.replace(/(<([^>]+)>)/gi, "") }</p>

                        <Link to={data.acf.promo.link.url} className="promo-link plus-btn">
                            <div>{data.acf.promo.link.title}</div><div className="icon"></div>
                        </Link>

                    </div>
                </div>
            </div> 

        : null}

        </div>
    );
}
