import React, { useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Store } from '../../Store';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from 'axios';

import api from '../../api/api';

import Error404 from '../Error404/Error404';

import './News.scss';

import readingIcon from '../../assets/icons/reading-white-icon.svg';

export default function News() {
    const { dispatch } = useContext(Store);
    const { page } = useParams();

    const perPage = 100;
    const offset = page ? (page - 1) * perPage : 0;

    const [{ data, loading, error }] = useAxios(`${api.news}?_embed&per_page=${perPage}&offset=${offset}`);

    function getSeo() {
        axios.get('https://phpstack-238280-4410839.cloudwaysapps.com/admin/wp-json/yoast/v1/get_head?url=' + window.location.href).then(res => {
            const seo = res.data;

            if (seo) {
                document.title = seo.json.og_title ? seo.json.og_title : 'News - Creative Monster';
                document.querySelector('meta[name="description"]').setAttribute("content", seo.json.og_description ? seo.json.og_description : '');
            }
        }).catch(function(e) {

        })
    }

    useEffect(() => {
        dispatch({ type: 'SET_WHITE_HEADER', payload: true });

        getSeo();
        
        return () => dispatch({ type: 'SET_WHITE_HEADER', payload: false });
    }, [dispatch]);

    if (loading) {
        return (
            <main className="news-content">
                <div className="container container-padding">
                    <h1 className="header header-lg">News</h1>
    
                    <div className="news-list">
                        <div className="news-small loading">
                            <div className="news-small-inner">
                                <div className="image"><div></div></div>
                                
                                <div className="text">
                                    <div className="text-inner">
                                        <div className="meta"></div>
                                        <div className="header"></div>
                                    </div>
                                    
                                    <div className="plus-icon"></div>
                                </div>
                            </div>
                        </div>
                        <div className="news-small loading" style={{ animationDelay: '0.2s' }}>
                            <div className="news-small-inner">
                                <div className="image"><div></div></div>
                                
                                <div className="text">
                                    <div className="text-inner">
                                        <div className="meta"></div>
                                        <div className="header"></div>
                                    </div>
                                    
                                    <div className="plus-icon"></div>
                                </div>
                            </div>
                        </div>
                        <div className="news-small loading" style={{ animationDelay: '0.4s' }}>
                            <div className="news-small-inner">
                                <div className="image"><div></div></div>
                                
                                <div className="text">
                                    <div className="text-inner">
                                        <div className="meta"></div>
                                        <div className="header"></div>
                                    </div>
                                    
                                    <div className="plus-icon"></div>
                                </div>
                            </div>
                        </div>
                        <div className="news-small loading" style={{ animationDelay: '0.6s' }}>
                            <div className="news-small-inner">
                                <div className="image"><div></div></div>
                                
                                <div className="text">
                                    <div className="text-inner">
                                        <div className="meta"></div>
                                        <div className="header"></div>
                                    </div>
                                    
                                    <div className="plus-icon"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }

    if (error)
        return <Error404 />

    return (
        <main className="news-content">
            <div className="container container-padding">
                <h1 className="header header-lg">News</h1>

                <TransitionGroup className="news-list">
                    { data.map( (news, index) =>
                        <CSSTransition key={news.id} in={true} appear={true} timeout={{ enter: 50 * index }} classNames="fade">
                            <article className="news-small">
                                <Link to={`/news/${news.slug}`}>
                                    <div className="news-small-inner">
                                        { news.featured_image ? <div className="image"><LazyLoadImage src={news.featured_image.news_list} alt={ news.title.rendered ? news.title.rendered : '' } effect="opacity" /></div> : null }
                                        
                                        <div className="text">
                                            <div className="text-inner">
                                                <div className="meta">
                                                    { news.post_categories && news.post_categories.length ? 
                                                        <div>{news.post_categories.map((category, index) => `${category.name}${index !== news.post_categories.length - 1 ? ', ' : '' }`)}</div> 
                                                    : null }

                                                    { news.post_length ? 
                                                        <div>{ readingIcon ? <img src={readingIcon} alt="" className="icon" /> : null }{ news.post_length } min read</div>
                                                    : ''}
                                                </div>
                                                
                                                { news.title.rendered ? <h1 className="header">{ news.title.rendered }</h1> : null }
                                            </div>
                                            
                                            <div className="plus-icon"></div>
                                        </div>
                                    </div>
                                </Link>
                            </article>
                        </CSSTransition>
                    ) }
                </TransitionGroup>
            </div>
        </main>
    );

    /*
    return (
        <main className="news-content">
            <TransitionGroup>
                { data.map( (news, index) =>
                    <CSSTransition key={news.id} in={true} appear={true} timeout={{ enter: 50 * index }} classNames="fade">
                        <article className="news-small">
                            <Link to={`/news/${news.slug}`} className="news-small-inner">
                                <div className="container container-padding">
                                    <div className="meta">
                                        { news.post_categories && news.post_categories.length ? 
                                            <div>{news.post_categories.map((category, index) => `${category.name}${index !== news.post_categories.length - 1 ? ', ' : '' }`)}</div> 
                                        : null }

                                        { news.post_length ? 
                                            <div>{ readingIcon ? <img src={readingIcon} alt="" className="icon" /> : null }{ news.post_length } min read</div>
                                        : ''}
                                    </div>
                                    
                                    { news.title.rendered ? <h1 className="header">{ news.title.rendered }</h1> : null }

                                    <div>Read more</div>
                                </div>

                                { news.featured_image ? <div className="overlay" style={{ backgroundImage: `url('${news._embedded['wp:featuredmedia'][0].media_details.sizes.news_list.source_url}')` }}></div> : null }
                            </Link>
                        </article>
                    </CSSTransition>
                ) }
            </TransitionGroup>
        </main>
    );
    */
}
