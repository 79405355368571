import React, { useContext, useRef, useEffect } from 'react';
import { useLaxElement } from 'use-lax';
import { Store } from '../../Store';

import './WorkSingleDescription.scss';

export default function WorkSingleDescription({ bigText, text, link, clients, deliverables, textColor }) {
    const { dispatch } = useContext(Store);

    const sectionRef = useRef();
    const workDescriptionInnerRef = useLaxElement();

    useEffect(() => {
        function sectionHeaderColor() {
            if (textColor === 'dark' && sectionRef.current && window.scrollY >= sectionRef.current.offsetTop && window.scrollY < sectionRef.current.offsetTop + sectionRef.current.clientHeight) {
                dispatch({ type: 'SET_WHITE_HEADER', payload: false });
            }
        }

        window.addEventListener('scroll', sectionHeaderColor);

        return (() => {
            window.removeEventListener('scroll', sectionHeaderColor);
        });
    }, [textColor, sectionRef, dispatch]);

    return (
        <div ref={sectionRef} className="work-description container container-padding">
            <div ref={workDescriptionInnerRef} className={`work-description-inner ${textColor}-text`} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
                { bigText ? 
                    <div className="big-text" dangerouslySetInnerHTML={{ __html: bigText }}></div>
                : null }
                <div className="text">
                    <div className="meta">
                        { clients ? 
                            <div><strong>Client:</strong> {clients.map((client, index) => `${client.name}${index !== clients.length - 1 ? ', ' : '' }`)}</div> 
                        : null }
                        { deliverables ? 
                            <div><strong>Deliverables:</strong> {deliverables.map((deliverable, index) => `${deliverable.name}${index !== deliverables.length - 1 ? ', ' : '' }`)}</div> 
                        : null }
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    { link ? <div className="cta"><a href={link.url} target={link.target} className={`plus-btn ${ textColor }`}>{link.title}<div className="icon"></div></a></div> : null }
                </div>
            </div>
        </div>
    );
}
