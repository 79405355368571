import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { CSSTransition } from 'react-transition-group';
import { useLaxElement } from 'use-lax';

import api from '../../api/api';

import Error404 from '../Error404/Error404';

import './NewsSingle.scss';

import readingDarkGreyIcon from '../../assets/icons/reading-dark-grey-icon.svg';

function NewsSingleFeaturedImage({ image, alt }) {
    const imageRef = useLaxElement();

    return <img ref={imageRef} src={ image } alt={ alt } className="featured-image lax" data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1" />
}

function NewsSingleContent({ news }) {
    const textRef = useLaxElement();

    return (
        <CSSTransition in={true} appear={true} timeout={500} classNames="loaded" unmountOnExit>
            <main className="news-single-content">
                <article className="news-single">

                    { news.title.rendered ? <h1 className="header container container-padding">{ news.title.rendered }</h1> : null }

                    <div className="meta-wrap container container-padding">
                        <div className="meta">
                            { news.post_categories && news.post_categories.length ? 
                                <div>{news.post_categories.map((category, index) => `${category.name}${index !== news.post_categories.length - 1 ? ', ' : '' }`)}</div> 
                            : null }

                            { news.post_length ? 
                                <div>{ readingDarkGreyIcon ? <img src={readingDarkGreyIcon} alt="" className="icon" /> : null }{ news.post_length } min read</div>
                            : ''}

                            <div><Link to="/news/" className="plus-btn">Back<div className="icon"></div></Link></div>
                        </div>
                    </div>

                    { news.featured_image && news.featured_image.full_width ? <NewsSingleFeaturedImage image={news.featured_image.full_width} alt={news.title.rendered} /> : null }

                    <div ref={textRef} className="news-text container container-padding" data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1" dangerouslySetInnerHTML={ news.content.rendered ? { __html: news.content.rendered } : null}></div>

                </article>
            </main>
        </CSSTransition>
    );
}

export default function NewsSingle() {
    const { newsSlug } = useParams();
    const [{ data, loading, error }] = useAxios(`${api.news}?_embed&slug=${newsSlug}`);

    if (loading || error)
        return <div className="full-screen"></div>;

    if (!data.length)
        return <Error404 />

    const news = data[0];

    document.title = news.yoast_head_json.og_title ? news.yoast_head_json.og_title : 'News - Creative Monster';

    document.querySelector('meta[name="description"]').setAttribute("content", news.yoast_head_json.og_description ? news.yoast_head_json.og_description : '');

    return (
        <NewsSingleContent news={news} />
    );
}
