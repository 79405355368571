import React from 'react';
import { Link } from 'react-router-dom';
import useAxios from 'axios-hooks';
import Slider from 'react-slick';
import { useLaxElement } from 'use-lax';

import api from '../../api/api';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import './HomeServices.scss';

import chevronLeftIcon from '../../assets/icons/chevron-left-icon.svg';
import chevronRightIcon from '../../assets/icons/chevron-right-icon.svg';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-prev slick-arrow" +
            (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
    >
        <img src={chevronLeftIcon} alt="" className="arrow-left" />
    </button>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
    >
        <img src={chevronRightIcon} alt="" className="arrow-right" />
    </button>
);

function SlideHeading(props) {
    const ref = useLaxElement();

    return (
        <h2 ref={ref} data-lax-anchor="self" data-lax-translate-x="(vh*0.75) 30, (-elh) 0" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 0.2" className="header-lg">{ props.text }</h2>
    )
}

function SlideContent(props) {
    const service = props.service;

    return (
        <div className="slide">
            <div 
                className="slide-wrapper" 
                style={{ 
                    backgroundImage: `url(${service.featured_image.full_width})`, 
                    backgroundColor: service.background_color, 
                }}
            >
                <div className="slide-content">
                    <SlideHeading text={ service.title.rendered } />
                    <h3 className="sub-header">{ service.content.rendered.replace(/(<([^>]+)>)/gi, "") }</h3>

                    { service.slug === 'ecommerce' ?

                        <Link to={`${service.slug}/`} className="post-link plus-btn white">
                            <div>View More {service.title.rendered}</div><div className="icon"></div>
                        </Link>

                        :

                        <Link to={`/services/${service.slug}/`} className="post-link plus-btn white">
                            <div>View More {service.title.rendered}</div><div className="icon"></div>
                        </Link>
                    }



                    { service.background_image_mobile.url ? 
                        <img src={service.background_image_mobile.url} 
                            alt={service.title.rendered} 
                            className="slide-image" /> 
                    : null }
                </div>
            </div>
        </div>
    )
}

const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
};

export default function HomeServices() {
    const [{ data }] = useAxios(`${api.services}?_embed&wpse_custom_order=menu_order&order=asc&per_page=100`);

    const services = [];

    //Set order of slides
    const order = ['ecommerce', 'animation', 'web']
    
    if (data) {
        data.forEach(service => {
            if (service.content.rendered) {
                services.push(service);
            }
        });
    }


    services.sort(function(a, b){
        return order.indexOf(a.slug) - order.indexOf(b.slug);
    })



    const sliderWrapRef = useLaxElement();
    
    return (
        <div className="home-services" ref={sliderWrapRef} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">

            {/*<pre>{JSON.stringify(data, null, 2) }</pre>*/}

            { services ? 
                <div className="slider-wrapper">
                    <Slider {...sliderSettings} className="slider">
                        { services.map(service => 
                           <SlideContent key={ service.id } service={ service } />
                        ) }
                    </Slider>
                </div>
            : null }
        </div>
    );
}
