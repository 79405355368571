import React from 'react';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import { Store } from '../../Store';

import './Ecommerce.scss';
import EcommerceBanner from '../EcommerceBanner/EcommerceBanner';
import TextLogoSection from '../TextSection/TextLogoSection';
import EcommerceLargeSlider from '../EcommerceLargeSlider/EcommerceLargeSlider';
import Platforms from '../Platforms/Platforms';
import VideoText from '../VideoText/VideoText';
import EcommerceStats from '../EcommerceStats/EcommerceStats';
import Contact from "../ContactCTA/Contact";

export default function Ecommerce() {
    document.title = 'Ecommerce - Creative Monster';

    return (
        <main className="about-content">
            <EcommerceBanner/>

            <TextLogoSection/>

            <EcommerceLargeSlider/>

            <Platforms/>

            <VideoText/>

            <EcommerceStats/>

            <Contact/>
        </main>
    )
}
