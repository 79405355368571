import React from 'react';
import { Link } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

import './SiteFooter.scss';

import logoDark from '../../assets/images/logo-dark.svg';

export default function SiteFooter() {

    return (
        <footer id="site-footer">
            <div className="container container-padding-md">
                <img src={logoDark} alt="Creative Monster" id="footer-logo" />

                <div className="footer-socials">
                    <a className="social-link" href="https://www.instagram.com/_creative_monster" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26.25" height="26.25" viewBox="0 0 26.25 26.25">
                            <path id="Path_9765" data-name="Path 9765" d="M8.379,10a6.548,6.548,0,0,1,4.8-1.992,6.7,6.7,0,0,1,6.738,6.738,6.7,6.7,0,0,1-6.738,6.738A6.7,6.7,0,0,1,6.445,14.75,6.453,6.453,0,0,1,8.379,10Zm1.7,7.852a4.385,4.385,0,0,0,6.211,0,4.385,4.385,0,0,0,0-6.211,4.385,4.385,0,0,0-6.211,0,4.385,4.385,0,0,0,0,6.211ZM21.27,6.664a1.338,1.338,0,0,1,.469,1.055A1.572,1.572,0,0,1,21.3,8.832a1.427,1.427,0,0,1-1.084.469,1.6,1.6,0,0,1-1.582-1.582A1.427,1.427,0,0,1,19.1,6.635,1.572,1.572,0,0,1,20.215,6.2,1.338,1.338,0,0,1,21.27,6.664Zm4.98,2.7q.059,1.582.059,5.391t-.059,5.391a8.081,8.081,0,0,1-2.139,5.537,7.874,7.874,0,0,1-5.537,2.08q-1.582.117-5.391.117t-5.391-.117a8.041,8.041,0,0,1-5.508-2.109,5.654,5.654,0,0,1-1.23-1.641,8.29,8.29,0,0,1-.674-1.816,9.1,9.1,0,0,1-.205-2.051Q.059,18.559.059,14.75T.176,9.3A7.963,7.963,0,0,1,2.285,3.852,7.9,7.9,0,0,1,7.793,1.684q1.582-.059,5.391-.059t5.391.059a8.081,8.081,0,0,1,5.537,2.139A8.081,8.081,0,0,1,26.25,9.359ZM23.438,22.484a6.355,6.355,0,0,0,.293-1.2q.117-.732.176-1.758t.059-1.67V11.645q0-.645-.059-1.67T23.73,8.217a6.355,6.355,0,0,0-.293-1.2,4.229,4.229,0,0,0-2.52-2.52,6.355,6.355,0,0,0-1.2-.293q-.732-.117-1.758-.176t-1.641-.059h-6.24q-.645,0-1.67.059T6.65,4.2a6.355,6.355,0,0,0-1.2.293,4.229,4.229,0,0,0-2.52,2.52,6.355,6.355,0,0,0-.293,1.2q-.117.732-.176,1.758T2.4,11.615V17.24q0,.557.029,1.436T2.52,20.17q.059.615.176,1.26t.234,1.055A4.442,4.442,0,0,0,5.449,25a6.355,6.355,0,0,0,1.2.293q.732.117,1.758.176t1.641.059h6.24q.645,0,1.67-.059t1.758-.176a6.355,6.355,0,0,0,1.2-.293A4.442,4.442,0,0,0,23.438,22.484Z" transform="translate(-0.059 -1.625)" fill="#414042" />
                        </svg>
                    </a>

                    <a className="social-link" href="https://dribbble.com/creativemonster" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29.063" height="29.063" viewBox="0 0 29.063 29.063">
                            <path id="Path_9765" data-name="Path 9765" d="M4.688,4.5A14.055,14.055,0,0,1,15,.219,14.007,14.007,0,0,1,25.283,4.467,14.007,14.007,0,0,1,29.531,14.75a14.007,14.007,0,0,1-4.248,10.283A14.007,14.007,0,0,1,15,29.281,14.007,14.007,0,0,1,4.717,25.033,14.007,14.007,0,0,1,.469,14.75,13.959,13.959,0,0,1,4.688,4.5ZM24.609,6.9A12.2,12.2,0,0,1,23.7,7.953,18.6,18.6,0,0,1,21.445,9.77,17.345,17.345,0,0,1,17.7,11.82q.527,1.172,1.055,2.461a25.007,25.007,0,0,1,8.672.352A12.225,12.225,0,0,0,24.609,6.9ZM23.2,5.492a12.1,12.1,0,0,0-5.215-2.783A12.375,12.375,0,0,0,12.07,2.68,56.229,56.229,0,0,1,16.7,9.945a15.2,15.2,0,0,0,3.6-1.816,13.522,13.522,0,0,0,2.109-1.67A11.724,11.724,0,0,0,23.2,5.492ZM9.727,3.559A12.426,12.426,0,0,0,4.189,8.627a11.1,11.1,0,0,0-1.318,3.6A42.426,42.426,0,0,0,14.3,10.707,65.658,65.658,0,0,0,9.727,3.559ZM2.578,14.75A11.9,11.9,0,0,0,5.8,23.07a16.974,16.974,0,0,1,2.578-3.4,20.216,20.216,0,0,1,3.691-2.988,15.418,15.418,0,0,1,4.277-1.934,17.133,17.133,0,0,0-1-2.109A42.028,42.028,0,0,1,2.578,14.4Zm4.8,9.785a11.8,11.8,0,0,0,6.094,2.578,11.806,11.806,0,0,0,6.387-.937A48.632,48.632,0,0,0,17.227,16.8a18.34,18.34,0,0,0-6.182,3.369A13.9,13.9,0,0,0,7.383,24.535Zm14.531.527a11.869,11.869,0,0,0,5.332-8.32,16.592,16.592,0,0,0-7.734-.527A51.071,51.071,0,0,1,21.914,25.063Z" transform="translate(-0.469 -0.219)" fill="#414042" />
                        </svg>
                    </a>

                    <a className="social-link" href="https://vimeo.com/creativemonster" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26.25" height="26.25" viewBox="0 0 26.25 26.25">
                            <path id="Path_9765" data-name="Path 9765" d="M23.613,1.625A2.6,2.6,0,0,1,26.25,4.262V25.238a2.6,2.6,0,0,1-2.637,2.637H2.637a2.548,2.548,0,0,1-1.875-.762A2.548,2.548,0,0,1,0,25.238V4.262A2.548,2.548,0,0,1,.762,2.387a2.548,2.548,0,0,1,1.875-.762Zm-1.523,8.73q.176-3.75-2.754-3.809Q15.41,6.43,14,11a2.284,2.284,0,0,1,1.846-.264q.967.264.791,1.553a7.01,7.01,0,0,1-1.23,2.871q-1.582,2.461-2.285,1.641-.7-.879-1.7-6.973Q10.9,6.547,8.73,6.781a2.9,2.9,0,0,0-.879.293,6.222,6.222,0,0,0-1.084.732q-.615.5-1.055.879L4.395,9.828q-.879.762-1.289,1.113l.938,1.172a5.206,5.206,0,0,1,1.465-.879q.7,0,1.23,1.377t1.523,5.01q.41,1.465.586,2.285,1.172,3.047,2.813,3.047,2.7,0,6.563-5.039Q21.973,13.109,22.09,10.355Z" transform="translate(0 -1.625)" fill="#414042" />
                        </svg>
                    </a>

                    <a className="social-link" href="https://www.facebook.com/creativmonster/" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.25" height="26.25" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" fill="#414042"/></svg>
                    </a>

                    <a className="social-link" href="https://www.linkedin.com/company/creative-monster" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.25" height="26.25" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" fill="#414042"/></svg> 
                    </a>
                </div>

                <p>© Copyright blah blah blah. <Link to="/privacy-policy/">Privacy Policy</Link>. <Link to="/cookie-policy/">Cookies Policy</Link>.</p>
                <p>All Termites Preserved (Seriously, why are you reading this?).</p>
            </div>

            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="CookieConsent"
                containerClasses="cookie-banner"
                disableStyles={true}
            >
                <h1>Cookie Tracking for the Best Monster Experience</h1>
                <p>Creative Monster may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our website www.creativemonster.co.uk, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”) to help customize the Site and improve your experience.</p>
            </CookieConsent>
        </footer>

    );
}
