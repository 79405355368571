import React, { useState, useRef, useEffect } from 'react';

import './AboutVideo.scss';

import playIcon from '../../assets/icons/play-icon.svg';

export default function AboutVideo({ mainVideo, nextStep, backgroundColor, setBackgroundColor, muteVideo }) {
    const [showPlayBtn, setShowPlayBtn] = useState(true);

    const mainVideoRef = useRef();
    const mainVideoCanvasRef = useRef();

    function playMainVideo() {
        if (mainVideoRef.current)
            mainVideoRef.current.play();
    }

    useEffect(() => {
        const mainVideo = mainVideoRef.current;
        const mainVideoCanvas = mainVideoCanvasRef.current;

        const ctx = mainVideoCanvas.getContext('2d');

        function playNextVideo() {
            nextStep();
        }

        function rgbToHex(r, g, b) {
            if (r > 255 || g > 255 || b > 255)
                throw new Error({ message : 'Invalid color component' });
            return ((r << 16) | (g << 8) | b).toString(16);
        }

        const drawFrame = function(v,c) {
            const w = mainVideo.videoWidth;
            const h = mainVideo.videoHeight;
            c.canvas.width = w;
            c.canvas.height = h;
            c.drawImage(v,0,0,w,h);
            const pixelData = c.getImageData(0, 0, 1, 1).data;
            const hex = "#" + ("000000" + rgbToHex(pixelData[0], pixelData[1], pixelData[2])).slice(-6);

            if (hex !== backgroundColor) {
                setBackgroundColor(hex);
            }
            
            v.timeout = setTimeout(drawFrame, 250, v,c);
        }

        drawFrame(mainVideo, ctx);

        mainVideo.addEventListener('ended', playNextVideo);
        mainVideo.addEventListener('play', () => { 
            setShowPlayBtn(false);
            if (typeof mainVideo.timeout === 'number') clearTimeout(mainVideo.timeout);
            drawFrame(mainVideo, ctx);
        });

        return () => {
            mainVideo.removeEventListener('ended', playNextVideo);
            if (typeof mainVideo.timeout === 'number') clearTimeout(mainVideo.timeout);
        }
    }, [mainVideoRef, mainVideoCanvasRef, setShowPlayBtn, nextStep, backgroundColor, setBackgroundColor]);
   
    return (
        <div className="about-video">
            <div className={`play-video-btn-wrap ${showPlayBtn ? 'show' : ''}`}>
                <div className="container container-padding">
                    <div className="play-video-btn">
                        <button type="button" onClick={ playMainVideo }>
                            <div className="icon"><img src={playIcon} alt="Play" /></div>
                            <div>Play Video</div>
                        </button>
                    </div>
                </div>
            </div>
            { mainVideo ?
                <video ref={mainVideoRef} className="main-video" autoPlay playsInline="playsInline" muted={ muteVideo ? true : false }>
                    <source src={mainVideo} type="video/mp4" />
                </video>
            : null }
            { mainVideo ?
                <canvas ref={mainVideoCanvasRef}></canvas>
            : null }
        </div>
    );
}
