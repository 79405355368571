import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './EcommerceLargeSlider.scss';
import FadeIn from "../Animations/FadeIn";

import slide1 from '../../assets/images/large-slide-1.png';
import slide2 from '../../assets/images/large-slide-2.png';
import slide3 from '../../assets/images/large-slide-3.png';
import leftArrow from '../../assets/icons/chevron-left-dark.svg';
import leftArrowWhite from '../../assets/icons/chevron-left-icon.svg';
import rightArrow from '../../assets/icons/chevron-right-dark.svg';
import rightArrowWhite from '../../assets/icons/chevron-right-icon.svg';


export default class EcommerceLargeSlider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    state = {
        activeSlide: 0,
        activeSlide2: 0
    };

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
            afterChange: current => this.setState({ activeSlide2: current })
        };
        return (
            <div className="large-slide-container">
                <FadeIn>
                    <Slider ref={c => (this.slider = c)} {...settings}>

                        <div>
                            <div className="slide-bg" style={{backgroundImage: "url("+slide1+")"}}></div>
                        </div>

                        <div>
                            <div className="slide-bg" style={{backgroundImage: "url("+slide2+")"}}></div>
                        </div>

                        <div>
                            <div className="slide-bg" style={{backgroundImage: "url("+slide3+")"}}></div>
                        </div>

                    </Slider>

                    <div className="slider-header">
                        <h3 className="header-md">
                            <span className={this.state.activeSlide > 1 ? 'text-white' : null}>
                                Ecommerce Clients
                            </span>
                        </h3>
                    </div>

                    <div className="arrow-container">
                        <div className="arrow-icon" onClick={this.previous}>
                            <img src={leftArrow} alt=""/>
                            <img className={this.state.activeSlide > 1 ? null : 'hidden'} src={leftArrowWhite} alt=""/>
                        </div>

                        <div className="arrow-icon" onClick={this.next}>
                            <img src={rightArrow} alt=""/>
                            <img className={this.state.activeSlide > 1 ? null : 'hidden'} src={rightArrowWhite} alt=""/>
                        </div>
                    </div>
                </FadeIn>
            </div>
        );
    }
}