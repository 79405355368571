import React, { useContext, useEffect } from 'react';
import { Store } from '../../Store';

import HomeBanner from '../HomeBanner/HomeBanner';
import HomeServices from '../HomeServices/HomeServices';
import HomeWork from '../HomeWork/HomeWork';
import HomeClients from '../HomeClients/HomeClients';
import HomeNews from '../HomeNews/HomeNews';
import HomeContact from '../HomeContact/HomeContact';
import axios from 'axios';

import './Home.scss';

export default function Home(props) {
    const { dispatch } = useContext(Store);
    document.title = 'Creative Monster - Home of Monster Magic™';

    function getSeo() {
        axios.get('https://phpstack-238280-4410839.cloudwaysapps.com/admin/wp-json/wp/v2/pages?slug=home').then(res => {
            const seo = res.data[0];

            if (seo) {
                document.title = seo.yoast_head_json.og_title ? seo.yoast_head_json.og_title : 'Creative Monster - Home of Monster Magic™';
                document.querySelector('meta[name="description"]').setAttribute("content", seo.yoast_head_json.og_description ? seo.yoast_head_json.og_description : '');
            }
        }).catch(function(e) {

        })
    }
    
    useEffect(() => {
        getSeo();

        return () => dispatch({ type: 'SET_PREV_PAGE', payload: 'home' });
        // eslint-disable-next-line
    }, []);

    return (
        <main className="home-content">
            <HomeBanner />
            <HomeServices />
            <HomeWork />
            <HomeClients />
            <HomeNews />
            <HomeContact />
        </main>
    );
}
