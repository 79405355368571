import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { scrollToggle } from '../../Helpers';
import WorkList from '../WorkList/WorkList';
import useAxios from 'axios-hooks';
import { useQueryState } from 'react-router-use-location-state';
import axios from 'axios';

import api from '../../api/api';

import './Work.scss';

export default function Work() {
    const workCategory = useQueryState('category', '');

    const [ filterActive, setFilterActive ] = useState(false);
    const [ selectedFilter, setSelectedFilter ] = useState();
    const [ workCategoryId, setWorkCategoryId ] = useState(false);
    
    const [{ data, loading, error }] = useAxios(api.workCategories);

    function closeFilter() {
        setFilterActive(false);
        scrollToggle(true);
    }

    function toggleFilter() {
        if (filterActive) {
            scrollToggle(true);
        } else {
            scrollToggle();
        }

        setFilterActive( !filterActive );
    }

    // eslint-disable-next-line
    function getSeo() {
        axios.get('https://phpstack-238280-4410839.cloudwaysapps.com/admin/wp-json/yoast/v1/get_head?url=' + window.location.href).then(res => {
            const seo = res.data;

            if (seo) {
                document.title = seo.json.og_title ? seo.json.og_title : 'Work - Creative Monster';
                document.querySelector('meta[name="description"]').setAttribute("content", seo.json.og_description ? seo.json.og_description : '');
            }
        }).catch(function(e) {
            document.title = 'Work - Creative Monster';
        })
    }

    useEffect(() => {
        if (workCategory[0]) {
            if (data) {
                data.map(category => {
                    if (category.slug === workCategory[0]) {
                        setWorkCategoryId(category.id);
                        setSelectedFilter(category.name);
                        
                        return true;
                    }

                    return false;
                });
            }
        } else {
            setWorkCategoryId(false);
            setSelectedFilter('');
        }

        getSeo();
    }, [data, workCategory, getSeo]);


    return (
        <main className="work-content">

            {/*<pre>{ JSON.stringify(seo, null, 2) }</pre>*/}

            <section className={`work-filter fixed-el center-section ${ filterActive ? 'show' : '' }`}>
                <div className="work-filter-inner container container-padding">
                    <div>
                        <Link to="/work/" onClick={() => { closeFilter(); setSelectedFilter(false); setWorkCategoryId(false); }}>
                            <span>All</span>
                        </Link>
                    </div>
                    { !loading && !error && data ? data.map(category => 
                        <div key={ category.id }>
                            <Link to={`/work?category=${ category.slug }`} onClick={() => { closeFilter(); setSelectedFilter(category.name); setWorkCategoryId(category.id); }}>
                                <span dangerouslySetInnerHTML={{ __html: category.name }}></span><sup>{ /* category.count */ }</sup>
                            </Link>
                        </div>    
                    ) : null }
                </div>
            </section>

            <div className="work-top container container-padding">
                <h1 className="header">Work</h1>
                <button type="button" onClick={toggleFilter}>Filter{ selectedFilter ? <span dangerouslySetInnerHTML={{ __html: `: ${selectedFilter}` }}></span> : null }</button>
            </div>

            <WorkList setSelectedFilter={setSelectedFilter} workCategory={workCategory} workCategoryId={workCategoryId} />
        </main>
    );
}
