import React from 'react';

import './Contact.scss';
import { Link } from "react-router-dom";

export default function Contact() {
    return (
        <div className="contact-content contact-cta">
            <div className="contact">
                <div className="container container-padding">
                    <h1 className="header header-lg">Contact</h1>

                    <div className="contact-details-wrap">
                        <div className="contact-details">
                            <div className="contact-details-text">
                                <strong>Don’t be afraid to make the first move, we don’t bite.</strong>
                            </div>

                            <Link to="/contact" className="plus-btn">Let’s Get Chatting <div className="icon"></div></Link>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
