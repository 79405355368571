import React, { useState } from 'react';
import axios from 'axios';
import { useLaxElement } from 'use-lax';

import api from '../../api/api';

import './ContactForm.scss';

export default function ContactForm() {
    const [messageFormLoading, setMessageFormLoading] = useState(false);
    const [messageName, setMessageName] = useState('');
    const [messageEmail, setMessageEmail] = useState('');
    const [messagePhone, setMessagePhone] = useState('');
    const [messageDetails, setMessageDetails] = useState('');

    const [messageServerError, setMessageServerError] = useState(false);
    const [messageNameError, setMessageNameError] = useState(false);
    const [messageEmailError, setMessageEmailError] = useState(false);
    const [messagePhoneError, setMessagePhoneError] = useState(false);
    const [messageDetailsError, setMessageDetailsError] = useState(false);

    const [messageSent, setMessageSent] = useState(false);

    const formRef = useLaxElement();

    async function submitMessageForm(e) {
        e.preventDefault();

        if (messageFormLoading)
            return;

        setMessageFormLoading(true);

        let res = await axios.post(`${api.adminPost}?action=submit_message_form&name=${messageName}&email=${messageEmail}&phone=${messagePhone}&details=${messageDetails}`);

        setMessageFormLoading(false);

        if (res.data && res.data.status === 'error') {
            setMessageServerError(res.data.errors.general ? res.data.errors.general : false);

            setMessageNameError(res.data.errors.name ? res.data.errors.name : false);

            setMessageEmailError(res.data.errors.email ? res.data.errors.email : false);

            setMessagePhoneError(res.data.errors.phone ? res.data.errors.phone : false);

            setMessageDetailsError(res.data.errors.details ? res.data.errors.details : false);
        } else if (res.data.status === 'success') {
            setMessageSent(true);
        }

        // Hubspot
        const data = {
            "fields": [
                {
                    "name": "firstname",
                    "value": messageName
                },
                {
                    "name": "email",
                    "value": messageEmail
                },
                {
                    "name": "phone",
                    "value": messagePhone
                },
                {
                    "name": "notes",
                    "value": messageDetails
                },
                {
                    "name": "creative_monster_customer",
                    "value": true
                }
            ],
        }

        axios.post('https://api.hsforms.com/submissions/v3/integration/submit/6828968/5546f8a6-ab94-45fb-84ec-672dc7335fbc', data).then(response => {
            //console.log(response);
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="message-form">
            <div className={`message-form-inner ${messageFormLoading ? 'loading' : ''}`}>
                {!messageSent ? (
                    <form ref={formRef} onSubmit={submitMessageForm} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
                        {messageServerError ? <div className="error">{messageServerError}</div> : null}

                        {messageNameError ? <div className="error">{messageNameError}</div> : null}

                        <div className="input-wrap name-input-wrap">
                            <label htmlFor="message-name">Hi, my name is</label>
                            <input type="text" value={messageName} placeholder="Name" id="message-name" onChange={e => setMessageName(e.target.value)} />
                        </div>

                        {messageEmailError ? <div className="error">{messageEmailError}</div> : null}

                        <div className="input-wrap">
                            <label htmlFor="message-email">My email address is </label>
                            <input type="email" value={messageEmail} placeholder="name@email.com" id="message-email" onChange={e => setMessageEmail(e.target.value)} />
                        </div>

                        {messagePhoneError ? <div className="error">{messagePhoneError}</div> : null}

                        <div className="input-wrap phone-input-wrap">
                            <label htmlFor="message-phone">My phone number is </label>
                            <input placeholder="01234 567890" value={messagePhone} id="message-phone" onChange={e => setMessagePhone(e.target.value)} />
                        </div>

                        {messageDetailsError ? <div className="error">{messageDetailsError}</div> : null}

                        <div className="input-wrap">
                            <label htmlFor="message-details">So, the sort of thing I'm after is </label>
                            <textarea id="message-details" value={messageDetails} onChange={e => setMessageDetails(e.target.value)} />
                        </div>
                        <button type="submit">Send</button>

                    </form>

                ) : null}
                {messageSent ? (
                    <div className="message-success">
                        Your message has been successfully sent.
                    </div>
                ) : null}
            </div>

        </div>

    )
}

