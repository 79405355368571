import React, { useEffect } from 'react';
import axios from 'axios';

import './CookiePolicy.scss';

export default function TermsAndConditions() {
    function getSeo() {
        axios.get('https://phpstack-238280-4410839.cloudwaysapps.com/admin/wp-json/yoast/v1/get_head?url=' + window.location.href).then(res => {
            const seo = res.data;

            if (seo) {
                document.title = seo.json.og_title ? seo.json.og_title : 'Contact - Creative Monster';
                document.querySelector('meta[name="description"]').setAttribute("content", seo.json.og_description ? seo.json.og_description : '');
            }
        }).catch(function(e) {

        })
    }

    useEffect(() => {
        getSeo();
    }, []);

    return (
        <main className="privacy-policy-content lg-container container-padding">
            <article className="privacy-policy">
                <h1 className="header">Cookie Policy</h1>
                <h2 className="sub-header">The legal stuff</h2>

                <p>Visiting this site may generate cookies that get stored on your computer or mobile device. Session cookies are used as a ‘memory’ for the website so you only get asked for information on a single occasion and it’s remembered for the remainder of your browsing session.</p>

                <p>We also use analytics cookies to help us understand how many people visit our site, what they look at, how they find us and to give us an understanding of how our site is performing. This data is anonymous and cannot be used to identify individuals.</p>

                <p>Please find a breakdown below, detailing the types of cookies we are using and what they are used for.</p>

                <h3>Cookies we use on this website</h3>

                <p>We have listed some commonly used cookies. Include following as applicable:</p>

                <p>Google Analytics (Persistent &amp; session): These analyse use of our website by storing cookies on a users computer. We receive reports about use. Google stores this information see <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a></p>

                <p>Viewer _utma: Anonymously identifies each user so we can identify how many different people visit over time - expires after 2 years.<br />
                Session _utmb: Both identify each unique visit to website expires after 30 minutes of inactivity.<br />
                and _utmc: Expires when you close your browser</p>

                <p>Opt out of Google cookies at <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a></p>

                <h3>How to turn off cookies</h3>

                <p>All modern browsers allow you to change your cookie settings. The following links may be helpful in helping you understand how you can update your browser cookie settings:</p>

                <ul>
                    <li><a href="http://support.microsoft.com/kb/196955" target="_blank" rel="noopener noreferrer">Cookie settings in Internet Explorer</a></li>
                    <li><a href="http://support.mozilla.com/en-US/kb/Cookies" target="_blank" rel="noopener noreferrer">Cookie settings in Firefox</a></li>
                    <li><a href="http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647" target="_blank" rel="noopener noreferrer">Cookie settings in Chrome</a></li>
                    <li><a href="http://docs.info.apple.com/article.html?path=Safari/3.0/en/9277.html" target="_blank" rel="noopener noreferrer">Cookie settings in Safari</a></li>
                </ul>

                <h3>Further information</h3>

                <p>To learn more about how advertisers use cookies and how you can control these please visit Your Online Choices.</p> 

                <p>To learn more about your rights to online privacy, please visit Data Protection Act 1998 and The Information Commissioner’s Office.</p>

                <p>If you would like to contact us directly about our use of cookies, please email us at <a href="mailto:hello@creativemonster.co.uk">hello@creativemonster.co.uk</a>.</p>
            </article>
        </main>
    )
}
