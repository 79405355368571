import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class SlickSlider extends Component {
    render() {
        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            appendDots: dots => (
                <div
                    style={{
                        bottom: "70px"
                    }}
                >
                    <ul className="dots-container" style={{ margin: "0px", listStyle: "none" }}> {dots} </ul>
                </div>
            )
        };
        return (
            <div className="slide-container">
                <Slider {...settings}>

                    {this.props.slides.map((el, index) => {
                        return <div key={index}>
                                    <div className="slide-bg" style={{
                                        backgroundImage: "url("+el+")"
                                    }}>

                                    </div>
                            </div>
                    })}

                </Slider>
            </div>
        );
    }
}