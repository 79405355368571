import React, { useContext, useRef, useEffect } from 'react';
import { Store } from '../../Store';
import { useLaxElement } from 'use-lax';

import './WorkSingleBanner.scss';

export default function WorkBanner({ id, title, logo, logoAlt, backgroundImage, logoPosition }) {
    const { state, dispatch } = useContext(Store);
    const { fullScreenHeight } = state;

    const sectionRef = useRef();
    const backgroundRef = useLaxElement();

    useEffect(() => {
        function sectionHeaderColor() {
            if (window.scrollY >= sectionRef.current.offsetTop && window.scrollY < sectionRef.current.offsetTop + sectionRef.current.clientHeight) {
                dispatch({ type: 'SET_WHITE_HEADER', payload: true });
            }
        }

        window.addEventListener('scroll', sectionHeaderColor);

        return (() => {
            window.removeEventListener('scroll', sectionHeaderColor);
        });
    }, [sectionRef, dispatch]);
    
    return (
        <div ref={sectionRef} id={id} className="work-banner full-screen" style={{ minHeight: fullScreenHeight }}>
            <div ref={backgroundRef} className="background" style={{ backgroundImage: `url(${backgroundImage})` }} data-lax-anchor={`#${id}`} data-lax-translate-y="0 0, elh 120"></div>
            
            <img src={backgroundImage} alt={title} className="background-image" />

            { logo ?
                <div className={`work-banner-inner full-screen center-section ${logoPosition}`} style={{ minHeight: fullScreenHeight }}>
                    <div className="logo container">
                        <img src={logo} alt={logoAlt} />
                    </div>
                </div>
            : null }

            <div className="scroller-wrapper">
                <div>
                    <div className="scroller">
                        <span></span>
                    </div>
                    <div className="scroller-text">SCROLL</div>
                </div>
            </div>
        </div>
    );
}
