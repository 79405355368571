import React from 'react';
import Slider from './SlickSlider';
import './EcommerceBanner.scss';

import banner1 from '../../assets/images/ecommerce-banner-1.png';
import banner2 from '../../assets/images/ecommerce-banner-2.png';
import {Link} from "react-router-dom";

export default function EcommerceBanner() {
    return (
        <div style={{ backgroundColor: '#F8F8F9' }} className="ecommerce-banner-container">
            <div className="ecommerce-banner container container-padding">
                <div className="ecommerce-banner-left">
                    <h1 className="header-lg">Monstrously good E&#x2011;commerce</h1>

                    <div className="ecommerce-list">
                        <ul>
                            <li>A beautifully designed site for an intuitive shopping experience</li>
                            <li>An ecommerce platform to perfectly fit your needs.</li>
                            <li>An online AI personal shopper to offer your customer tailored recommendations</li>
                        </ul>

                        <Link to="/contact" className="plus-btn">Contact Us <div className="icon"></div></Link>
                    </div>
                </div>

                <div className="ecommerce-banner-right">
                    <Slider slides={[banner1, banner2]}/>
                </div>
            </div>

            <div className="scroller-wrapper">
                <div>
                    <div className="scroller">
                        <span></span>
                    </div>
                    <div className="scroller-text">SCROLL</div>
                </div>
            </div>
        </div>
    );
}
