import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Store } from '../../Store';

import './AboutText.scss';

export default function AboutStep({ stepIndex, stepText, button, link, linkText, backgroundColor, textColor, timeoutColors, timeoutHeaderColor, timeoutBackgroundColor, timeoutDelay, setTextColor, nextStep, previousStep, muteVideo, setMuteVideo, children }) {
    const { dispatch } = useContext(Store);

    const [aboutTextColor, setAboutTextColor] = useState(textColor ? textColor : '');

    useEffect(() => {
        let textColorChangeTimeout = null;

        setTextColor(textColor);

        if (textColor === 'white') {
            dispatch({ type: 'SET_WHITE_HEADER', payload: true });
        } else {
            dispatch({ type: 'SET_WHITE_HEADER', payload: false });
        }

        if (timeoutDelay) {
            textColorChangeTimeout = setTimeout(() => {
                dispatch({ type: 'SET_WHITE_HEADER', payload: timeoutHeaderColor === 'white' });
                setAboutTextColor(timeoutColors);
                setTextColor(timeoutColors);
            }, timeoutDelay);
        }

        return () => {
            clearTimeout(textColorChangeTimeout);
        }

    }, [dispatch, backgroundColor, textColor, timeoutColors, timeoutBackgroundColor, timeoutHeaderColor, timeoutDelay, setTextColor, setAboutTextColor]);

    return (
        <div className="about-step-text">
            <div className="text">
                { stepText ? <div className="about-step-no">{stepText}</div> : null}

                <div dangerouslySetInnerHTML={{ __html: children }}></div>
            </div>

            <div className="about-step-btns">
                { stepIndex !== 0 ? <button className={`plus-btn underline ${aboutTextColor}`} onClick={previousStep}>Back<div className="icon"></div></button> : null }
                { button ? <button className={`plus-btn underline ${aboutTextColor}`} onClick={nextStep}>{button}<div className="icon"></div></button> : null }
                { link && linkText ? <Link className={`plus-btn underline ${aboutTextColor}`} to={link}>{linkText}<div className="icon"></div></Link> : null }
            </div>

            <div>
                <button onClick={ () => setMuteVideo(!muteVideo) } className={`about-mute-btn ${ muteVideo ? 'mute' : '' } ${aboutTextColor}`}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 93.5 66.8">
                        <path d="M41.8 7.1c-1-.6-2.3-.6-3.3 0L15.8 20H3.3C1.5 20 0 21.5 0 23.4v20c0 1.8 1.5 3.3 3.3 3.3h12.5l22.6 12.9c1.6.9 3.6.4 4.6-1.2.3-.5.4-1.1.4-1.7V10c0-1.2-.6-2.3-1.6-2.9zm-5 43.9L18.4 40.5c-.5-.3-1.1-.4-1.7-.4h-10V26.7h10c.6 0 1.2-.2 1.7-.4l18.4-10.5V51z"/>
                        <g className="soundwave-1"><path d="M56.1 21.3c-1.2-1.4-3.3-1.6-4.7-.5-1.4 1.2-1.6 3.2-.5 4.6 3.5 4.7 3.5 11.1 0 15.9-1.2 1.4-1 3.5.4 4.7 1.4 1.2 3.5 1 4.7-.4 0 0 0-.1.1-.1 5.4-7.1 5.4-17.1 0-24.2z"/></g>
                        <g className="soundwave-2"><path d="M69.4 11.3c-1.2-1.4-3.3-1.6-4.7-.5-1.4 1.2-1.6 3.2-.5 4.6 8 10.6 8 25.3 0 35.9-1.2 1.4-1 3.5.4 4.7 1.4 1.2 3.5 1 4.7-.4 0 0 0-.1.1-.1 9.9-13.1 9.9-31.1 0-44.2z"/></g>
                        <g className="soundwave-3"><path d="M82.8 1.2C81.6-.2 79.5-.4 78.1.7c-1.4 1.2-1.6 3.2-.5 4.6C90 22 90 44.8 77.6 61.4c-1.2 1.4-1 3.5.4 4.7 1.4 1.2 3.5 1 4.7-.4 0 0 0-.1.1-.1 14.3-19.1 14.3-45.3 0-64.4z"/></g>
                    </svg>
                </button>
            </div>
        </div>
    );
}
