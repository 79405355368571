import React from 'react';
import './TextSection.scss';
import FadeIn from "../Animations/FadeIn";
import vogueSvg from '../../assets/images/logos/vogue-svg.svg';
import stormzySvg from '../../assets/images/logos/stormzy-svg.svg';
import glamourSvg from '../../assets/images/logos/glamour-svg.svg';
import daveSvg from '../../assets/images/logos/dave-svg.svg';
import tatlerSvg from '../../assets/images/logos/tatler-svg.svg';
import slowthaiSvg from '../../assets/images/logos/slowthai-svg.svg';


export default function TextLogoSection() {

    return (
        <div className="text-logo-section">
            <FadeIn>
                <div className="logo-row container container-padding">
                    <div className="logo-row-inner">
                        <div className="logo-container">
                            <img src={vogueSvg} alt=""/>
                        </div>

                        <div className="logo-container">
                            <img src={stormzySvg} alt=""/>
                        </div>

                        <div className="logo-container">
                            <img src={glamourSvg} alt=""/>
                        </div>

                        <div className="logo-container">
                            <img src={daveSvg} alt=""/>
                        </div>

                        <div className="logo-container">
                            <img src={tatlerSvg} alt=""/>
                        </div>

                        <div className="logo-container">
                            <img src={slowthaiSvg} alt=""/>
                        </div>
                    </div>
                </div>

                <div className="text-logo-inner container container-padding">
                    <div className="header-container">
                        <h3 className="header-sm">Beautifully designed and intuitive customer journeys to deliver a terrifyingly good online experience</h3>
                    </div>

                    <div className="content-container">
                        <p>Our monsters know how to build beautiful websites, but they also know how to turn them into more than something pretty. </p>

                        <p>Ecommerce sites can be a big task, with potentially thousands of products to manage, category pages, baskets and checkouts all needing to be considered. Ensuring your site showcases your products, is easy to navigate and truly designed with the user experience in mind, is absolutely essential.</p>

                        <p>No need to fear though, our monsters don’t bite, and they know exactly how to design a customer journey that will lead your customers straight to that magical ‘add to cart’ button. Our designs ensure that we get your online customers to where they want to be, offering them the products they actually want and need, which all leads to fewer abandoned baskets.</p>

                        <p>Take a look at some of our work below.</p>
                    </div>
                </div>
            </FadeIn>
        </div>
    );
}
