import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Store } from '../../Store';

import './SiteNav.scss';

import instagramIcon from '../../assets/icons/instagram-icon.svg';
import dribbbleIcon from '../../assets/icons/dribbble-icon.svg';
import vimeoIcon from '../../assets/icons/vimeo-icon.svg';

function SiteNavLink({ title, link, closeNav }) {
    return (
        <div>
            <Link to={link} className="site-nav-link" onClick={closeNav}>
                <div className="icon"></div>
                <div className="site-nav-link-inner">{title}</div>
            </Link>
        </div>
    );
}

function SiteNavSubMenu({ title, menu, closeNav }) {
    const [ showMenu, setShowMenu ] = useState(false);

    function openSubNav() {
        setShowMenu(true);
    }

    function closeSubNav() {
        setShowMenu(false);
    }

    function closeNavAndSubNav() {
        closeNav();

        setTimeout(() => {
            closeSubNav();
        }, 500);
    }
    
    return (
        <div className={showMenu ? 'show-sub-menu' : null}>
            <button type="button" className="site-nav-link" onClick={openSubNav}>
                <div className="icon"></div>
                <div className="site-nav-link-inner">{title}</div>
            </button>
            <div className="sub-menu fixed-el">
                <div className="sub-menu-inner container container-padding">
                    <button type="button" className="site-nav-link" onClick={closeSubNav}>
                        <div className="back-icon"></div>
                        <div className="site-nav-link-inner">{title}</div>
                    </button>
                    <div className="sub-menu-links">
                        { menu ? menu.map((menuItem, index) =>
                            <SiteNavLink key={index} title={menuItem.title} link={menuItem.link} closeNav={closeNavAndSubNav} />
                        ) : null }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function SiteNav({ closeNav }) {
    const { state } = useContext(Store);
    const { services } = state;

    const serviceLinks = [];
    
    services.forEach(service => {
        if (!service.parent) {
            serviceLinks[service.id] = { title: service.title, link: service.path };
        }
    });

    return (
        <nav id="site-nav" className="fixed-el">
            <div id="site-nav-inner">
                <div id="site-nav-links" className="container container-padding">
                    <SiteNavLink title="Home" link="/" closeNav={closeNav} />
                    <SiteNavSubMenu title="Services" menu={serviceLinks} closeNav={closeNav} />
                    <SiteNavLink title="Work" link="/work/" closeNav={closeNav} />
                    <SiteNavLink title="About" link="/about/" closeNav={closeNav} />
                    <SiteNavLink title="Careers" link="/careers/" closeNav={closeNav} />
                    <SiteNavLink title="News" link="/news/" closeNav={closeNav} />
                    <SiteNavLink title="Contact" link="/contact/" closeNav={closeNav} />
                </div>

                <div id="site-nav-social">
                    <div className="container container-padding">
                        Social
                        <div className="icons">
                            <a href="https://www.instagram.com/_creative_monster/" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="Instagram" /></a>
                            <a href="https://dribbble.com/creativemonster" target="_blank" rel="noopener noreferrer"><img src={dribbbleIcon} alt="Dribbble" /></a>
                            <a href="https://vimeo.com/creativemonster" target="_blank" rel="noopener noreferrer"><img src={vimeoIcon} alt="Vimeo" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
