import React from 'react';
import { Link } from 'react-router-dom';
import { useLaxElement } from 'use-lax';

import './HomeContact.scss';

export default function HomeContact() {

    const headerRef = useLaxElement();
    const contentRef = useLaxElement();
    
    return (
        <section className="home-contact">
            <div className="container container-padding">
                <h2 ref={headerRef} className="header header-lg" data-lax-anchor="self" data-lax-translate-x="(vh*0.75) 30, (-elh) -30" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 0.2">Contact</h2>
                <div ref={contentRef} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
                    <h3 className="sub-header">Don’t be afraid to make the first move, we don’t bite.</h3>
                    <Link to="/contact/" className="plus-btn white"><div>Lets get chatting</div><div className="icon"></div></Link>
                </div>
            </div>
        </section>
    );
}
