import React, { useContext, useState } from 'react';
import AnimateCC from 'react-adobe-animate';
import { Store } from '../../Store';
import Script from 'react-load-script';

import './PreLoader.scss';

export default function PreLoader() {
    const { state } = useContext(Store);
    const { pageLoaded } = state;
    const [ preloaderLoaded, setPreloaderLoaded ] = useState(false);

    return (
        <div id="pre-loader" className={`fixed-el ${pageLoaded || process.env.NODE_ENV !== 'production' ? 'hide' : ''}`}>
            { preloaderLoaded ? <AnimateCC animationName="PreloaderAnimation" /> : null }

            <Script
            url="/preloader-animation.js"
            onLoad={() => { if (!preloaderLoaded) setPreloaderLoaded(true) }} />
        </div>
    );
}
