import React, { useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import { Link } from 'react-router-dom';
import { Store } from '../../Store';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useLaxElement } from 'use-lax';
import AnimateCC from 'react-adobe-animate';
import Script from 'react-load-script';
import axios from 'axios';

import api from '../../api/api';

import './Careers.scss';

function CareerSmall({ career }) {
    const { state } = useContext(Store);
    const { services } = state;

    const [ serviceAnimationLoaded, setServiceAnimationLoaded ] = useState(false);
    const [ serviceAnimationName, setServiceAnimationName ] = useState(false);
    const [ serviceAnimationScript, setServiceAnimationScript ] = useState(false);
    const [ serviceBackgroundColor, setServiceBackgroundColor ] = useState(false);

    useEffect(() => {
        const service = services.find((e) => e.title === career.service);

        if (service) {
            setServiceAnimationName(service.animationName);
            setServiceAnimationScript(service.animationScript);
            setServiceBackgroundColor(service.backgroundColor);
        }
    }, [career, services, setServiceAnimationName, setServiceAnimationScript, setServiceBackgroundColor]);

    return (
        <article className="career-small">
            <Link to={`/career/${career.slug}`} className="career-small-inner">
                <div className="animation-wrap" style={{ backgroundColor: serviceBackgroundColor }}>
                    { serviceAnimationLoaded ? <AnimateCC animationName={serviceAnimationName} /> : null }
                </div>
                <h1>{ career.title.rendered }</h1>
                <div className="plus-btn">Apply Now <div className="icon"></div></div>
            </Link>

            { serviceAnimationName && serviceAnimationScript ?
                <Script
                url={serviceAnimationScript}
                onLoad={() => { if (!serviceAnimationLoaded) setServiceAnimationLoaded(true) }} />
            : null }
        </article>
    );
}

export default function Careers() {
    const [{ data }] = useAxios(`${api.careers}?wpse_custom_order=menu_order&order=asc&per_page=100`);

    const textTopRef = useLaxElement();
    const careersListRef = useLaxElement();
    const textBottomRef = useLaxElement();

    function getSeo() {
        axios.get('https://phpstack-238280-4410839.cloudwaysapps.com/admin/wp-json/yoast/v1/get_head?url=' + window.location.href).then(res => {
            const seo = res.data;

            if (seo) {
                document.title = seo.json.og_title ? seo.json.og_title : 'Careers - Creative Monster';
                document.querySelector('meta[name="description"]').setAttribute("content", seo.json.og_description ? seo.json.og_description : '');
            }
        }).catch(function(e) {

        })
    }

    useEffect(() => {
        getSeo();
    }, []);

    return (
        <main className="careers-content">
            <div className="container container-padding">
                <div ref={textTopRef} className="careers-text" data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
                    <h1 className="header-lg">Careers</h1>
                    <div className="aboard-header">All aboard…</div>
                    <div>
                        <p>Creative Monster are always on the lookout for other visionaries to join the ranks. It’s simple, if you’re creative, then become a Monster.</p>
                    </div>
                </div>

                <div ref={careersListRef} className="career-opportunities" data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
                    <h2 className="header">Current Opportunities</h2>

                    <TransitionGroup className="career-opportunities-list">
                        { data ? data.map((career, index) =>
                            <CSSTransition key={career.id} in={true} appear={true} timeout={{ enter: 50 * index }} classNames="fade">
                                <CareerSmall career={career} />
                            </CSSTransition>
                        ) : null }
                    </TransitionGroup>

                    { data && !data.length ? <p>Sorry, we don't have any opportunities currently.</p> : null }

                </div>

                <div ref={textBottomRef} className="careers-text" data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
                    <h2 className="sub-header">What about me?</h2>
                    <div>
                        <p>If none of those take your fancy, we are always on the look out for new talent. Take a chance and get in touch, we don’t bite!</p>
                        <Link to="/contact/" className="plus-btn">Contact Us <div className="icon"></div></Link>
                    </div>
                </div>
            </div>
        </main>
    );
}
