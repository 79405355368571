import React from 'react';
import { Link } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { useLaxElement } from 'use-lax';

import api from '../../api/api';

import './HomeNews.scss';

export default function HomeNews() {
    const [{ data }] = useAxios(`${api.news}?_embed&order=desc&per_page=4`);

    const headerRef = useLaxElement();
    const contentRef = useLaxElement();

    return (
        <div className="home-news">
            <div className="container container-padding">
                <h2 ref={headerRef} className="header-lg" data-lax-anchor="self" data-lax-translate-x="(vh*0.75) 30, (-elh) -30" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 0.2">Blog</h2>

                <div ref={contentRef} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">

                    { data ?
                        <div className="home-news-posts">
                            { data.map( (news, index) =>

                                <article key={news.id} className="post">
                                    <Link to={`/news/${news.slug}`}>
                                        { news.featured_image ? <img src={news.featured_image.full_width} alt={ news.title.rendered ? news.title.rendered : '' } /> : null }
                                        <div className="post-date">
                                            {new Intl.DateTimeFormat("en-GB", {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit"
                                            }).format(new Date(news.date_gmt)).split("/").join(".")}
                                        </div>
                                        { news.title.rendered ? <h2 className="post-title sub-header">{ news.title.rendered }</h2> : null }
                                        <div className="post-link plus-btn white"><div>Read now</div><div className="icon"></div></div>
                                    </Link>
                                </article>

                            ) }
                        </div>
                    : null }

                </div>
            </div>
        </div>
    )
}
