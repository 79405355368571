import React, { useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Store } from '../../Store';
import { useLaxElement } from 'use-lax';
import useAxios from 'axios-hooks';

import api from '../../api/api';

import './WorkSingleNextPost.scss';

export default function WorkNextPost({ nextPostID }) {
    const { dispatch } = useContext(Store);
    const [{ data }] = useAxios(`${api.work}/${nextPostID}?_embed`);
    
    const sectionRef = useRef();

    const textRef = useLaxElement();

    useEffect(() => {
        function sectionHeaderColor() {
            if (sectionRef.current && window.scrollY >= sectionRef.current.offsetTop && window.scrollY < sectionRef.current.offsetTop + sectionRef.current.clientHeight) {
                dispatch({ type: 'SET_WHITE_HEADER', payload: true });
            }
        }

        window.addEventListener('scroll', sectionHeaderColor);

        return (() => {
            window.removeEventListener('scroll', sectionHeaderColor);
        });
    }, [sectionRef, dispatch]);

    const work = data;

    return (
        <Link ref={sectionRef} id="next-project" to={ work ? `/work/${work.slug}` : ''} className="next-project" style={ work && work.background_color ? { backgroundColor: work.background_color } : null }>
            <div ref={textRef} className="container container-padding" data-lax-anchor="#next-project" data-lax-translate-y="vh 90, (-elh) 0" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
                <div>
                    Next <br />
                    Project
                </div>
                
                <div className="icon"></div>
            </div>
            { work && work.featured_media ? <div className="background" style={ work.featured_image && work.featured_image.full_width ? { backgroundImage: `url(${work.featured_image.full_width})` } : '' }></div> : null }
        </Link>
    );
}
