import React, { useContext, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useLaxElement } from 'use-lax';
import { Store } from '../../Store';

import './WorkSingleVideo.scss';

export default function WorkVideo({ videoType, videoUrl, videoFile, videoPlaceholder, textColor }) {
    const { dispatch } = useContext(Store);

    const video = videoType === 'embed' ? videoUrl : videoFile;

    const containerRef = useLaxElement();

    useEffect(() => {
        function sectionHeaderColor() {
            if (textColor === 'dark' && containerRef.current && window.scrollY >= containerRef.current.offsetTop && window.scrollY < containerRef.current.offsetTop + containerRef.current.clientHeight) {
                dispatch({ type: 'SET_WHITE_HEADER', payload: false });
            }
        }

        window.addEventListener('scroll', sectionHeaderColor);

        return (() => {
            window.removeEventListener('scroll', sectionHeaderColor);
        });
    }, [textColor, containerRef, dispatch]);

    return (
        <div ref={containerRef} className="md-container md-container-padding" data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
            <div className="work-video">
                <ReactPlayer url={video} controls playing={ videoPlaceholder ? true : false } light={videoPlaceholder} width="1920px" height="1080px" />
            </div>
        </div>
    );
}
