import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ProgressBar.scss';

function ProgressBar(props){
    const styles = {
        width: props.value + '%',
    }
    return (
        <div className="progress-bar-container">
            <div className="progress-value">
                {props.value} %
            </div>
            <div className="progress-bar">
                <div className="bar" style={styles}></div>
                progress! {props.value}
            </div>

            <div className="progress-text">
                <p>{props.text}</p>
            </div>
        </div>
    );

}

export default ProgressBar;