import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './PrivacyPolicy.scss';

export default function PrivacyPolicy() {
    function getSeo() {
        axios.get('https://phpstack-238280-4410839.cloudwaysapps.com/admin/wp-json/yoast/v1/get_head?url=' + window.location.href).then(res => {
            const seo = res.data;

            if (seo) {
                document.title = seo.json.og_title ? seo.json.og_title : 'Contact - Creative Monster';
                document.querySelector('meta[name="description"]').setAttribute("content", seo.json.og_description ? seo.json.og_description : '');
            }
        }).catch(function(e) {

        })
    }

    useEffect(() => {
        getSeo();
    }, []);

    return (
        <main className="privacy-policy-content lg-container container-padding">
            <article className="privacy-policy">
                <h1 className="header">Privacy Policy</h1>
                <h2 className="sub-header">The legal stuff</h2>
                
                <p><i>Version 1.3 (22/04/2020)</i></p>

                <p>We respect the privacy of every person who visits or interacts with our websites and/or the websites of our clients (“Sites”); who uses our applications and/or the applications we build for our clients (“Applications”); and who otherwise utilises the goods and/or services that we provide (our “Services”). We are committed to ensuring transparency in terms of how we process personal data within our business.</p>

                <h3>Purpose of this Policy</h3>

                <p>This privacy policy (“Privacy Policy”) explains our approach to any personal information that we might collect from you or which we have obtained about you from a third party and the purposes for which we process your personal information. This Privacy Policy also sets out your rights in respect of our processing of your personal information.</p>

                <p>This Privacy Policy will inform you of the nature of the personal information about you that is processed by us and how you can request that we delete, update, transfer it and/or provide you with access to it.</p>

                <p>This Privacy Policy is intended to assist you in making informed decisions when using our Sites and Applications. Please take a moment to read and understand it.</p>

                <p>Please also note that this Privacy Policy only applies to the use of your personal information obtained by us, it does not apply to your personal information collected during your communications with third parties.</p>

                <p><strong>Who are we and what do we do?</strong><br />
                Our Sites and Applications are operated by Monster Holdings Ltd (t/a Creative Monster), (“we”, “us” or “our”).</p>

                <p>Monster Holdings Ltd is the data controller responsible for your personal information. If you have any question about this privacy notice, including any requests to exercise your legal rights, please contact us using the details set out below.</p>

                <p>Monster Holdings Ltd is an English company with registered company number: 10625728 and whose registered office is at Tate House, Watermark Way, Hertford, SG13 7TZ.</p>

                <h3>How to contact us?</h3>

                <p>If you have any questions about this Privacy Policy or want to exercise your rights set out in this Privacy Policy, please contact us by sending an email to <a href="mailto:dataprivacy@creativemonster.co.uk">dataprivacy@creativemonster.co.uk</a>. We will respond to any queries within one to three business working days.</p>

                <h3>What personal information do we collect and how do we use it?</h3>

                <p>Our primary goal in collecting personal information from you is to:</p>

                <ul>
                    <li>Fulfil our supply of goods and/or services to clients</li>
                    <li>Manage our client relationships</li>
                    <li>Handle enquiries</li>
                    <li>Help us improve our services</li>
                    <li>Promote and market our services</li>
                    <li>Conduct surveys</li>
                    <li>Conduct user insight and analysis</li>
                    <li>Recruit external personnel</li>
                    <li>Comply with any applicable law, court order, other judicial process, or the requirements of a regulator; and use as otherwise required or permitted by law.</li>
                </ul>

                <p>In particular, we use your personal information for the following purposes:</p>

                <h3>Fulfilment of products, services and features of the Sites and Applications</h3>
                
                <p>We collect and maintain personal information that you voluntarily submit to us by email, or during your use of the Sites, Applications, our products and/or our Services. We use the personal data you provide to us so that we can fulfil the supply of Services you have engaged us to provide. We will also use your data to coordinate your attendance at events hosted by us or our third-party partners, if you have provided it for that purpose.</p>

                <p>Sometimes, we may be granted access to certain user data (including personal data) when we help our clients build, maintain and develop their own products, applications or software.</p>

                <p><strong>Who do we share your personal information with for this purpose?</strong><br />
                We might share your personal information with the following categories of third parties:</p>

                <ul>
                    <li>Web hosting, optimisation and management companies - who assist us with the hosting, operation, optimisation and maintenance of our Site and the administration of your client account with us.</li>
                    <li>Events - any applicable third-party hosting or facilitating the event.</li>
                    <li>Storage - third party cloud storage providers that store our data on secure servers.</li>
                </ul>

                <p>We do not share user data (or other personal data) that we might inadvertently process as part of helping our clients build, maintain and develop their own products, applications or software.</p>

                <p><strong>What is our legal basis?</strong><br />
                It is necessary for us to use your personal information in this way to perform our obligations in accordance with any contract that we may have with you, or to which the data subject is otherwise a party.</p>

                <h3>Client relationship management and enquiries via our Site</h3>

                <p>Our Sites feature a “Contact Us” page which invites you to submit general enquiries, media enquiries, and competition enquiries. We collect the personal data you submit to us when making your enquiry.<br />
                Where you are a prospective client, we may use personal information that you voluntarily submit to us to prepare proposals for your purchase of our Services, to contact you, or otherwise to manage or administer our relationship with you.</p>

                <p><strong>Who do we share your personal information with for this purpose?</strong><br />
                We do not actively share your personal information with any third parties, but we do store your data using secure third-party servers based in the cloud.</p>

                <p><strong>What is our legal basis?</strong><br />
                It is in our legitimate interest to use your personal information in such a way to ensure that we provide the very best client service we can to you.</p>

                <h3>User insight and analysis</h3>

                <p>We may analyse your activity and interactions with the Sites and Applications. We do this either as a way of helping us to improve our own Sites (as a controller), or as part of the services we provide to our clients (as a processor).</p>

                <p>Where we process your user data as part of the services we provide to clients, this allows us to advise them on key analytics relating to their websites and apps. This helps our clients tailor projects for your benefit.</p>

                <p>Where you have given your consent (where lawfully required), we may use cookies, log files and other technologies to collect personal information in this way. This may include the following:</p>

                <ul>
                    <li>An IP address to monitor traffic and volume on the Sites;</li>
                    <li>A session ID to track usage statistics on the Sites; or</li>
                    <li>Information regarding your personal or professional interests, demographics, participation habits, experiences with our projects and interactive features.</li>
                </ul>

                <p>Our Sites and emails may contain cookies, web beacons and pixel tags (“Tags”). Tags allow us to track receipt of an email to you, to count users that have visited a web page or opened an email and collect other types of aggregate information. Once you click on an email that contains a Tag, your contact information may subsequently be cross-referenced to the source e-mail and the relevant Tag.</p>

                <p>In some of our email messages, we use a “click-through URL” linked to certain websites administered by us or on our behalf. We may track click-through data to assist in determining interest in particular topics and measure the effectiveness of these communications.</p>

                <p>Please see our <Link to="/cookie-policy/">Cookie Policy</Link> for further information.</p>

                <p>This information is used to create profiles and insights about your interaction with the Sites and Applications. Where we have your consent to do so, we may also use your location data for user insight purposes.</p>

                <p>By using this information, we are able to measure the effectiveness of content and how visitors use the Sites, Applications and our Services. This allows us to learn what pages of the Sites and Applications are most attractive to visitors, which parts are the most interesting, and what kind of offers registered users like to see.</p>

                <p><strong>Who do we share your personal information with for these purposes?</strong><br />
                We share your personal information with a variety of third-party service providers to assist us with user insight analytics. These providers include those described in our <Link to="/cookie-policy/">Cookie Policy</Link>.</p>

                <p><strong>What is our legal basis?</strong><br />
                Where your personal information is completely anonymised, we do not require a legal basis to use it as the information will no longer constitute personal information that is regulated under data protection laws. However, our collection and use of such anonymised personal information may be subject to other laws where your consent is required. Please see our <Link to="/cookie-policy/">Cookie Policy</Link> for further details.</p>

                <p>Where your personal information is not in an anonymous form, it is in our legitimate interest to use your personal information in such a way to ensure that we provide the very best services to you and our other customers. We will only use your location data for user insight purposes where we have your consent to do so.</p>

                <h3>Surveys and feedback</h3>

                <p>From time to time we will contact you to invite you to provide feedback about our Sites, Products and/or Services in the form of online surveys. Alternatively, you may submit queries or feedback to us via the “Contact Us” section on our Site. We use this information to help us improve the quality of service provided by us. We also use your feedback to monitor the quality of our services and to assist with the selection of future interactive features and service lines.</p>

                <p><strong>Who do we share your personal information with for these purposes?</strong><br />
                We often use a third-party service provider to assist us with customer surveys and feedback requests.</p>

                <p><strong>What is our legal basis?</strong><br />
                It is in our legitimate business interests to use the information you provide to us in your feedback for the purposes described above.</p>

                <h3>Marketing communications</h3>

                <p>We carry out the following marketing activities using your personal information:</p>

                <p><strong>Email marketing</strong><br />
                We may use your personal information to send you marketing communications by email, where you have consented to receive such marketing communications, or where we have another lawful basis to do so. Please let us know if you would like to be <a href="https://creativemonster.us2.list-manage.com/subscribe" target="_blank" rel="noopener noreferrer">added or removed</a> from our mailing list.</p>

                <p>Sometimes, as part of the services we are engaged to provide to clients, we advise them on (a) how to capture and store user data from their websites and apps, and (b) how to use this user data to inform their email marketing strategy. We process your personal information in these circumstances as a processor (not a controller).</p>

                <p><strong>Who do we share your personal information with for these purposes?</strong><br />
                We share your personal information with a variety of third-party email marketing providers who assist us in delivering our email marketing campaigns to you.</p>

                <p><strong>What is our legal basis?</strong><br />
                Where your personal information is completely anonymised, we do not require a legal basis to use it as the personal information will no longer constitute personal information that is regulated under data protection laws. However, our collection and use of such anonymised personal information may be subject to other laws where your consent is required. Please see our <Link to="/cookie-policy/">Cookie Policy</Link> for further details.</p>

                <p>Where your personal information is not in an anonymous form, it is in our legitimate interest to use your personal information for marketing purposes.</p>

                <p>We will only send you marketing communications via email where you have consented to receive such marketing communications, or where we have a lawful right to do so.</p>

                <h3>Recruitment</h3>

                <p>We use your personal information for recruitment purposes, in particular, to assess your suitability for any position for which you may apply for with us, whether such application has been received by us online, by email or by hard copy and whether submitted directly by you or by a third party recruitment agency on your behalf. We also use your personal information to communicate with you about the recruitment process, to keep records about our recruitment process and to comply with our legal and regulatory obligations in relation to recruitment.</p>

                <p>We may process any personal information about you that you volunteer when you apply for a job with us. We may also process your personal information obtained from any third parties we work with in relation to our recruitment activities, including without limitation, recruitment agencies, background check providers, credit reference agencies, your referees and the Disclosure and Barring Service (if applicable).</p>

                <p>The personal information we process may include your name and contact information (e.g. email address, postal address, telephone number), personal information concerning your education, qualifications and employment history and any other personal information which appears in your curriculum vitae and covering letter, which you volunteer in an application form or during an interview or which is contained in any reference we receive about you. Such information may also include special categories of personal information (such as information about your health, any medical conditions and your health and sickness records) and information relating to criminal convictions and offences if that information is relevant to the role you are applying for.</p>

                <p>We also use your personal information for the purposes of reviewing our equal opportunity profile in accordance with applicable legislation. We do not discriminate on the grounds of gender, race, ethnic origin, age, religion, sexual orientation, disability or any other basis covered by local legislation. All employment-related decisions are made entirely on merit.</p>

                <p><strong>Who do we share your personal information with for these purposes?</strong><br />
                We may share your personal information with external recruiters or third parties who provide background checks. We may also share your personal information with third parties who assist us with the recruitment process.</p>

                <p><strong>What is our legal basis?</strong><br />
                Where we use your personal information in connection with recruitment, it will be in connection with us taking steps at your request to enter into a contract we may have with you or it is in our legitimate interest to use personal information in such a way to ensure that we can make appropriate and informed recruitment decisions.<br />
                We will not process any special (or sensitive) categories of personal information or personal information relating to criminal convictions or offences except where we are able to do so under applicable legislation or with your explicit consent.</p>

                <h3>Business administration and legal compliance</h3>

                <p>We use your personal information for the following business administration and legal compliance purposes:</p>

                <ul>
                    <li>to comply with our legal obligations;</li>
                    <li>to enforce our legal rights;</li>
                    <li>protect rights of third parties; and</li>
                    <li>in connection with a business transition such as a merger, acquisition by another company, or sale of all or a portion of our assets.</li>
                </ul>

                <p><strong>Who do we share your personal information with for these purposes?</strong><br />
                We may share your personal information with professional advisers such as lawyers and accountants and/or governmental or regulatory authorities.</p>

                <p><strong>What is our legal basis?</strong><br />
                Where we use your personal information in connection with a business transition, enforce our legal rights, or to protect the rights of third parties it is in our legitimate interest to do so. For all other purposes described in this section, it is our legal obligation to use your personal information to comply with any legal obligations imposed upon us such as a court order.</p>

                <h3>How do we obtain your consent?</h3>

                <p>Where our use of your personal information requires your consent, you can provide such consent:</p>

                <ul>
                    <li>at the time we collect your personal information following the instructions provided; or</li>
                    <li>by informing us by email, post or phone using the Contact Details set out in this Privacy Policy.</li>
                </ul>

                <h3>Our use of cookies and similar technologies</h3>

                <p>Our Sites use certain cookies, pixels, beacons, log files and other technologies of which you should be aware. Please see our <Link to="/cookie-policy/">Cookie Policy</Link> to find out more about the cookies we use and how to manage and delete cookies.</p>

                <h3>Third Party Links and Services</h3>

                <p>Our Sites may contain links to third party websites and services. Please remember that when you use a link to go from our Sites to another website or you request a service from a third party, this Privacy Policy no longer applies.</p>

                <p>Your browsing and interaction on any other websites, or your dealings with any other third-party service provider, is subject to that website’s or third-party service provider’s own rules and policies.</p>

                <p>We do not monitor, control, or endorse the privacy practices of any third parties linked to our Sites. We encourage you to become familiar with the privacy practices of every website you visit or third-party service provider that you deal with and to contact them if you have any questions about their respective privacy policies and practices.</p>

                <p>This Privacy Policy applies solely to personal information collected by us through our Service and does not apply to these third-party websites and third-party service providers.</p>

                <p><strong>How long do we keep your personal information for?</strong><br />
                We keep your personal information indefinitely so that we have a record of requests you have made in the past e.g. requests for information in relation to our Products and Services. It is also important for us to maintain a record of your personal information in the event that you have asked us to no longer contact you.</p>

                <p>If your information is only useful for a short period e.g. for specific marketing campaigns, we may delete it.</p>

                <p><strong>Confidentiality and security of your personal information</strong><br />
                We are committed to keeping the personal information you provide to us secure and we will take reasonable precautions to protect your personal information from loss, misuse or alteration.</p>

                <p>We have implemented information security policies, rules and technical measures to protect the personal information that we have under our control from:</p>

                <ul>
                    <li>unauthorised access;</li>
                    <li>improper use or disclosure;</li>
                    <li>unauthorised modification; and</li>
                    <li>unlawful destruction or accidental loss.</li>
                </ul>

                <p>All of our employees and data processors (i.e. those who process your personal information on our behalf, for the purposes listed above), who have access to, and are associated with the processing of personal information, are obliged to respect the confidentiality of the personal information of all users of our Services.</p>

                <p><strong>How to access your information and your other rights?</strong><br />
                You have the following rights in relation to the personal information we hold about you:</p>

                <p><strong>Your right of access</strong><br />
                If you ask us, we’ll confirm whether we’re processing your personal information and, if so, provide you with a copy of that personal information (along with certain other details). If you require additional copies, we may need to charge a reasonable fee.</p>

                <p><strong>Your right to rectification</strong><br />
                If the personal information we hold about you is inaccurate or incomplete, you’re entitled to have it rectified. If we’ve shared your personal information with others, we’ll let them know about the rectification where possible. If you ask us, where possible and lawful to do so, we’ll also tell you who we’ve shared your personal information with so that you can contact them directly.</p>

                <p><strong>Your right to erasure</strong><br />
                You can ask us to delete or remove your personal information in some circumstances such as where we no longer need it or if you withdraw your consent (where applicable). If we’ve shared your personal information with others, we’ll let them know about the erasure where possible. If you ask us, where it is possible and lawful for us to do so, we’ll also tell you who we’ve shared your personal information with so that you can contact them directly.</p>

                <p><strong>Your right to restrict processing</strong><br />
                You can ask us to ‘block’ or suppress the processing of your personal information in certain circumstances such as where you contest the accuracy of that personal information or you object to us processing it. It won’t stop us from storing your personal information though. We’ll tell you before we lift any restriction. If we’ve shared your personal information with others, we’ll let them know about the restriction where it is possible for us to do so. If you ask us, where it is possible and lawful for us to do so, we’ll also tell you who we’ve shared your personal information with so that you can contact them directly.</p>

                <p><strong>Your right to data portability</strong><br />
                With effect from 25 May 2018, you have the right, in certain circumstances, to obtain personal information you’ve provided us with (in a structured, commonly used and machine readable format) and to reuse it elsewhere or to ask us to transfer this to a third party of your choice.</p>

                <p><strong>Your right to object</strong><br />
                You can ask us to stop processing your personal information, and we will do so, if we are:</p>

                <p>relying on our own or someone else’s legitimate interests to process your personal information, except if we can demonstrate compelling legal grounds for the processing; or<br />
                processing your personal information for direct marketing.</p>

                <p><strong>Your rights in relation to automated decision-making and profiling</strong><br />
                You have the right not to be subject to a decision when it’s based on automatic processing, including profiling, if it produces a legal effect or similarly significantly affects you, unless such profiling is necessary for entering into, or the performance of, a contract between you and us.</p>

                <p><strong>Your right to withdraw consent</strong><br />
                If we rely on your consent (or explicit consent) as our legal basis for processing your personal information, you have the right to withdraw that consent at any time. We will assess any deletion request on a case by case basis and will endeavour to respond as soon as possible, at most within 10 working days.</p>

                <p><strong>Your right to lodge a complaint with the supervisory authority</strong><br />
                If you have a concern about any aspect of our privacy practices, including the way we’ve handled your personal information, you can report it to the UK Information Commissioner’s Office (ICO). You can find details about how to do this on the ICO website at <a href="https://ico.org.uk/concerns/" target="_blank" rel="noopener noreferrer">https://ico.org.uk/concerns/</a> or by calling their helpline on 0303 123 1113.</p>

                <h3>Changes to this Privacy Policy</h3>
                
                <p>We may make changes to this Privacy Policy from time to time.</p>

                <p>To ensure that you are always aware of how we use your personal information, we will update this Privacy Policy from time to time to reflect any changes to our use of your personal information. We may also make changes as required to comply with changes in applicable law or regulatory requirements.</p>

                <p>We will make available to you here any significant changes to the policy, whether based on a change of law or otherwise. However, we encourage you to review this Privacy Policy periodically to be informed of how we use your personal information.</p>

            </article>
        </main>
    );
}
