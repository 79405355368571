import { useState, useEffect } from 'react';

function setFixedElWidths() {
    var $fixedEls = document.querySelectorAll('.fixed-el');

    [].forEach.call($fixedEls, function($el) {
        $el.style.width = document.body.offsetWidth + 'px';
    });
}

export function resizeOpenEls() {
    if (!document.body.classList.contains('no-scroll'))
        return;

    scrollToggle(true);

    setFixedElWidths();

    setTimeout(() => {
        scrollToggle();
    }, 100);
}

export function scrollToggle(show, timeout) {
    var $fixedEls = document.querySelectorAll('.fixed-el');

    if (show) {
        document.body.classList.remove('no-scroll');
        document.body.style.width = 'auto';

        if (!timeout) timeout = 0;

        setTimeout(() => {
            [].forEach.call($fixedEls, function($el) {
                $el.style.width = '';
            });

            document.body.style.width = '';
        }, timeout);
    } else {
        setFixedElWidths();
        
        document.body.style.width = document.body.offsetWidth + 'px';
        document.body.classList.add('no-scroll');
    }
}


export function useOnScreen(ref, rootMargin) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        {
            rootMargin
        }
    )

    useEffect(() => {
        // eslint-disable-next-line
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        // eslint-disable-next-line
        return () => { observer.disconnect() }
        // eslint-disable-next-line
    }, [])

    return isIntersecting
}

