import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { useQueryState } from 'react-router-use-location-state';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from 'axios';
import { Store } from '../../Store';

import api from '../../api/api';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import './WorkList.scss';

export default function WorkList({ workCategoryId }) {
	const { state, dispatch } = useContext(Store);
    const { work } = state;
    const [ loading, setLoading ] = useState(true);

    const hasWork = !work ? false : true;

    useEffect(() => {
        async function fetchWork() {
            let workApiUrl = `${api.work}?wpse_custom_order=menu_order&order=asc&per_page=100`;

            if (workCategoryId) {
                workApiUrl = `${workApiUrl}&work_category=${workCategoryId}`;
            }

            const result = await axios( workApiUrl );

            dispatch({ type: 'SET_WORK', payload: result.data });

            if (loading) {
                setLoading(false);
            }
        }

        /*
        function loadMoreWork() {
            if (!loading && window.scrollY + window.innerHeight > workList.current.offsetTop + workList.current.clientHeight) {
                fetchWork();
            }
        }

        window.addEventListener('scroll', loadMoreWork);

        return () => window.removeEventListener('scroll', loadMoreWork);
        */

        fetchWork();
 
    }, [ workCategoryId, dispatch, loading, hasWork ]);

    if (!work) {
        return (
            <section className="work-list container container-padding">
                <div className="work-list-inner">
                    <div className="work-small loading"><div className="image"></div></div>
                    <div className="work-small loading" style={{ animationDelay: '0.2s' }}><div className="image"></div></div>
                    <div className="work-small loading" style={{ animationDelay: '0.4s' }}><div className="image"></div></div>
                    <div className="work-small loading" style={{ animationDelay: '0.6s' }}><div className="image"></div></div>
                    <div className="work-small loading" style={{ animationDelay: '0.8s' }}><div className="image"></div></div>
                    <div className="work-small loading" style={{ animationDelay: '1s' }}><div className="image"></div></div>
                    <div className="work-small loading" style={{ animationDelay: '1.2s' }}><div className="image"></div></div>
                    <div className="work-small loading" style={{ animationDelay: '1.4s' }}><div className="image"></div></div>
                    <div className="work-small loading" style={{ animationDelay: '1.6s' }}><div className="image"></div></div>
                    <div className="work-small loading" style={{ animationDelay: '1.8s' }}><div className="image"></div></div>
                    <div className="work-small loading" style={{ animationDelay: '2s' }}><div className="image"></div></div>
                    <div className="work-small loading" style={{ animationDelay: '2.2s' }}><div className="image"></div></div>
                </div>
            </section>
        )
    }

    return (
        <section className="work-list container container-padding">
            <TransitionGroup className="work-list-inner">
                { work ? work.map((work, index) =>
                    <CSSTransition key={work.id} in={true} appear={true} timeout={{ enter: 50 * index, exit: 250 }} classNames="fade">
                        <article className="work-small">
                            <Link to={`/work/${work.slug}`}>
                                <div className="image">
                                    { work.featured_image && work.featured_image.work_list ? 
                                        <LazyLoadImage src={work.featured_image.work_list}
                                            alt={work.title.rendered}
                                            effect="opacity"  /> 
                                    : null }
                                </div>
                                <h2>{ work.title.rendered }</h2>
                            </Link>
                        </article>
                    </CSSTransition>
                ) : null }
            </TransitionGroup>
        </section>
    );
}
