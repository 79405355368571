import React, { createContext, useReducer } from 'react';

export const Store = createContext();

const initialState = {
    fullScreenHeight: document.documentElement.clientHeight,
    smFullScreenHeight: window.innerWidth < 768 ? 0 : document.documentElement.clientHeight,
    whiteHeader: false,
    prevPage: '',
    work: false,
    services: [
        {
            id: 1,
            title: 'Web',
            path: '/services/web/',
            animationName: 'DevelopmentMonster',
            animationScript: '/development-monster-animation.js',
            content: `
                <p>Web design and development requires a balanced relationship between form and function as this is fundamental in delivering a monstrously good online experience. Bespoke, outstanding creativity and an obsessive attention to detail shape our philosophy on how a concept becomes reality. Simply put, our monsters don’t stop until their work truly inspires.</p>
            `,
            workLink: '/work?category=website-and-development',
            backgroundColor: '#414042'
        },
        {
            id: 2,
            title: 'Branding',
            path: '/services/branding/',
            animationName: 'DesignerMonster',
            animationScript: '/designer-monster-animation.js',
            content: `
                <p>A monster’s objective is to craft a brand that makes people fall in love. That’s not easy but, hey we like to think we’ve gotten pretty good at it over the years. Intelligent design coupled with a clear understanding of your requirements is what makes all the difference. This is the foundation of everything we do.</p>
            `,
            workLink: '/work?category=branding',
            backgroundColor: '#DFBEA6'
        },
        {
            id: 3,
            title: 'Film',
            path: '/services/film/',
            animationName: 'FilmMonster',
            animationScript: '/film-monster-animation.js',
            content: `
                <p>Visual story telling is what we do so well. We realise the importance of creating compelling stories and film is the perfect way to captivate and engage any audience whilst also telling a brand story.</p>
            `,
            workLink: '/work?category=film',
            backgroundColor: '#F546B7'
        },
        {
            id: 4,
            title: 'Animation',
            path: '/services/animation/',
            animationName: 'AnimationMonster',
            animationScript: '/animation-monster-animation.js',
            content: `
                <p>With animation, anything is possible. If you can think it, our monsters can animate it. Or even if you can’t think it, our team will dream it up and bring it to life. We create characterful and engaging animations for global brands, start-ups and everyone in between. Covering everything from 2D, 3D, motiongraphics, character animation and yes, even CGI.</p>
            `,
            workLink: '/work?category=animation',
            backgroundColor: '#00C9B0'
        },
        {
            id: 5,
            title: 'Experience',
            path: '/services/experience/',
            animationName: 'ExperienceMonster',
            animationScript: '/experience-monster-animation.js',
            content: `
                <p>Immerse yourself and your customers in a new world, where anything is possible. We help to create an experience that takes you away, showing the infinite possibilities in VR, AR and visual shows, including projection mapping and 360 films. You may also want us to help make your physical event that unforgettable experience you always wished for... well we can do that too.</p>
            `,
            workLink: '/work?category=experience',
            backgroundColor: '#44C1DC'
        },
        {
            id: 6,
            title: 'Strategy',
            path: '/services/strategy/',
            animationName: 'StrategyMonster',
            animationScript: '/strategy-monster-animation.js',
            content: `
                <p>This is all about the how - How you communicate, how things look and how it comes together. In a nutshell, we figure out how to get you there successfully. Starting with a bid strategy, campaign architecture, through to concept design and collaboration before implementing across all marketing channels.</p>
            `,
            workLink: '/work',
            backgroundColor: '#FDA602'
        },
        {
            id: 7,
            title: 'Digital',
            path: '/services/digital/',
            animationName: 'DigitalSolutionsMonster',
            animationScript: '/digital-solutions-monster-animation.js',
            content: `
                <p>Accelerate your brand and engage with your customers in a digital world in both B2C and B2B markets with creative digital solutions that are powered by incredible people and intelligent applications. Become a part of the new normal, it’s time to go digital.</p>
            `,
            workLink: '/work?category=digital-solutions',
            backgroundColor: '#F67287'
        },
        {
            id: 8,
            title: 'Be Live',
            header: 'Digital',
            path: '/services/digital/be-live/',
            animationName: 'DigitalSolutionsMonster',
            animationScript: '/digital-solutions-monster-animation.js',
            content: `
                <p>Why not create an in-store experience online? Our monsters can humanise the customer experience by giving users that in-store experience wherever they are. Interact online with customers via text, video and track each sales journey through to purchase.</p>
            `,
            workLink: '/work?category=digital-solutions',
            backgroundColor: '#F67287',
            parent: 7
        },
        {
            id: 9,
            title: 'Be Social',
            header: 'Digital',
            path: '/services/digital/be-social/',
            animationName: 'DigitalSolutionsMonster',
            animationScript: '/digital-solutions-monster-animation.js',
            content: `
                <p>It’s time to amplify your social media by connecting, distributing and growing your social media footprint. We can make sharing simple by taking control of your employees and/or brand representatives social media calendar, as well as create some engaging content for them to share with the world.</p>
            `,
            workLink: '/work?category=digital-solutions',
            backgroundColor: '#F67287',
            parent: 7
        },
        {
            id: 10,
            title: 'Be Connected',
            header: 'Digital',
            path: '/services/digital/be-connected/',
            animationName: 'DigitalSolutionsMonster',
            animationScript: '/digital-solutions-monster-animation.js',
            content: `
                <p>Let us help your customers both online and in-store find the right products. Our platform offers personalised, intelligent customer guidance across any of your channels and customer touchpoints. By asking customers conversational questions we guide them to their perfect product, which allows them to feel confident in their purchase decision and in your brand.</p>
            `,
            workLink: '/work?category=digital-solutions',
            backgroundColor: '#F67287',
            parent: 7
        },
        // {
        //     id: 11,
        //     title: 'Be Educated',
        //     header: 'Digital',
        //     path: '/services/digital/be-educated',
        //     animationName: 'DigitalSolutionsMonster',
        //     animationScript: '/digital-solutions-monster-animation.js',
        //     content: `
        //         <p>Creative Monsters can craft personalised learning paths for every learner by giving users easy access to blended learning options. Our bespoke Learning Management System (LMS) allows you to organise all your content in one location, track learner progress and much more. We can also create engaging, branded content which make learning so much more fun.</p>
        //     `,
        //     workLink: '/work?category=digital-solutions',
        //     backgroundColor: '#F67287',
        //     parent: 7
        // },
        {
            id: 11,
            title: 'Be Virtual',
            header: 'Digital',
            path: '/services/digital/be-virtual/',
            animationName: 'DigitalSolutionsMonster',
            animationScript: '/digital-solutions-monster-animation.js',
            content: `
                <p>Whether you’re looking to host a Virtual Event or Webinar our monsters can create that stand-out experience you’ve been looking for! Our solution brings people and brands together at any scale, whether it’s on a global or local level, we create a bespoke platform that allows you to consistently engage with your audience and differentiate yourselves from your competitors.</p>
            `,
            workLink: '/work?category=digital-solutions',
            backgroundColor: '#F67287',
            parent: 7
        }
    ]
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FULL_SCREEN_HEIGHT':
            return { ...state, fullScreenHeight: action.payload };
        case 'SET_SM_FULL_SCREEN_HEIGHT':
            return { ...state, smFullScreenHeight: action.payload };
        case 'SET_WHITE_HEADER':
            return { ...state, whiteHeader: action.payload };
        case 'SET_PAGE_LOADED':
            return { ...state, pageLoaded: action.payload };
        case 'SET_PREV_PAGE':
            return { ...state, prevPage: action.payload };
        case 'SET_WORK':
            return { ...state, work: action.payload };
        default:
            return state;
    }
}

export function StoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };

    return <Store.Provider value={value}>{props.children}</Store.Provider>
}
