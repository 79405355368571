import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { Store } from '../../Store';
import { CSSTransition } from 'react-transition-group';

import api from '../../api/api';

import Error404 from '../Error404/Error404';

import WorkSingleBanner from '../WorkSingleBanner/WorkSingleBanner';
import WorkSingleDescription from '../WorkSingleDescription/WorkSingleDescription';
import WorkSingleBlockquote from '../WorkSingleBlockquote/WorkSingleBlockquote'
import WorkSingleImage from '../WorkSingleImage/WorkSingleImage';
import WorkSingleVideo from '../WorkSingleVideo/WorkSingleVideo';
import WorkSingleSpacer from '../WorkSingleSpacer/WorkSingleSpacer';
import WorkSingleNextPost from '../WorkSingleNextPost/WorkSingleNextPost';
import WorkSingleCollaborate from '../WorkSingleCollaborate/WorkSingleCollaborate';

import './WorkSingle.scss';

export default function WorkSingle() {
    const { dispatch } = useContext(Store);
    const { workSlug } = useParams();
    const [{ data, loading, error }] = useAxios(`${api.work}?slug=${workSlug}`);

    useEffect(() => {
        if (!loading && !error && data.length)
            dispatch({ type: 'SET_WHITE_HEADER', payload: true });
        
        return () => dispatch({ type: 'SET_WHITE_HEADER', payload: false });
    }, [dispatch, loading, error, data]);

    if (loading || error)
        return <div className="full-screen"></div>;

    if (!data.length)
        return <Error404 />

    const work = data[0];

    const clients = work.clients ? work.clients : false;
    const deliverables = work.deliverables ? work.deliverables : false;
    const textColor = work.text_color ? work.text_color : 'white';

    document.title = work.yoast_head_json.og_title ? work.yoast_head_json.og_title : 'Work - Creative Monster';

    document.querySelector('meta[name="description"]').setAttribute("content", work.yoast_head_json.og_description ? work.yoast_head_json.og_description : '');

    return (
        <CSSTransition in={true} appear={true} timeout={500} classNames="loaded" unmountOnExit>
            <main className="work-single-content">
                <article className="work-single" style={ work.background_color ? { backgroundColor: work.background_color } : null }>
                    { work.sections ? work.sections.map((section, index) => {

                        switch (section.acf_fc_layout) {
                            case 'banner':
                                var bannerSectionLogo = section.logo ? section.logo.url : false;
                                var bannerSectionLogoAlt = section.logo ? section.logo.alt : '';
                                var bannerSectionLogoPosition = section.logo_position ? 'logo-' + section.logo_position : 'logo-center';
                                var bannerSectionBackgroundImage = section.background_image ? section.background_image.sizes.full_width : false;

                                return <WorkSingleBanner key={`section-${index}`} id={`section-${index}`} logo={bannerSectionLogo} logoAlt={bannerSectionLogoAlt} logoPosition={bannerSectionLogoPosition} backgroundImage={bannerSectionBackgroundImage} />
                            
                            case 'description':
                                var descriptionSectionBigText = section.big_text ? section.big_text : false;
                                var descriptionSectionText = section.text ? section.text : false;
                                var descriptionSectionLink = section.link ? section.link : false;

                                return <WorkSingleDescription key={`section-${index}`} id={`section-${index}`} bigText={descriptionSectionBigText} text={descriptionSectionText} link={descriptionSectionLink} clients={clients} deliverables={deliverables} textColor={textColor} />
                            
                            case 'blockquote':
                                var blockquoteSectionText = section.text ? section.text : false;
                                var blockquoteSectionCitation = section.citation ? section.citation : false;

                                return <WorkSingleBlockquote key={`section-${index}`} id={`section-${index}`} text={blockquoteSectionText} citation={blockquoteSectionCitation} textColor={textColor} />
                            
                            case 'image':
                                var imageSectionImage = section.image ? section.image.sizes.full_width : false;
                                var imageSectionImageAlt = section.image ? section.image.alt : '';

                                return <WorkSingleImage key={`section-${index}`} id={`section-${index}`} image={imageSectionImage} imageAlt={imageSectionImageAlt} textColor={textColor} />
                            
                            case 'video':
                                var videoSectionVideoType = section.video_type ? section.video_type : false;
                                var videoSectionVideoUrl = section.video_url ? section.video_url : false;
                                var videoSectionVideoFile = section.video_file ? section.video_file : false;
                                var videoSectionVideoPlaceholder = section.video_placeholder ? section.video_placeholder.sizes.video_placeholder : false;

                                return <WorkSingleVideo key={`section-${index}`} id={`section-${index}`} videoType={videoSectionVideoType} videoUrl={videoSectionVideoUrl} videoFile={videoSectionVideoFile} videoPlaceholder={videoSectionVideoPlaceholder} textColor={textColor} />
                            
                            case 'spacer':
                                return <WorkSingleSpacer key={`section-${index}`} id={`section-${index}`} textColor={textColor} />

                            default :
                                return '';
                        }

                    }) : null }
                </article>

                { work.next_post_id ? <WorkSingleNextPost nextPostID={work.next_post_id} /> : null }

                <WorkSingleCollaborate />
            </main>
        </CSSTransition>
    );
}
