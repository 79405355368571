import React, { useContext, useEffect } from 'react';
import { useLaxElement } from 'use-lax';
import { Store } from '../../Store';

import './WorkSingleImage.scss';

export default function WorkImage({ image, imageAlt, textColor }) {
    const { dispatch } = useContext(Store);

    const imageWrapRef = useLaxElement();

    useEffect(() => {
        function sectionHeaderColor() {
            if (textColor === 'dark' && imageWrapRef.current && window.scrollY >= imageWrapRef.current.offsetTop && window.scrollY < imageWrapRef.current.offsetTop + imageWrapRef.current.clientHeight) {
                dispatch({ type: 'SET_WHITE_HEADER', payload: false });
            }
        }

        window.addEventListener('scroll', sectionHeaderColor);

        return (() => {
            window.removeEventListener('scroll', sectionHeaderColor);
        });
    }, [textColor, imageWrapRef, dispatch]);

    return (
        <div ref={imageWrapRef} className="work-image" data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
            <img src={image} alt={imageAlt} />
        </div>
    );
}
