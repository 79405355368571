import React, { useRef } from 'react';
import './VideoText.scss';
import PhoneVideo from '../PhoneVideo/PhoneVideo';
import FadeIn from "../Animations/FadeIn";
import { useOnScreen } from '../../Helpers';
import {Link} from "react-router-dom";

function VideoText() {
    const ref = useRef();

    const isVisible = useOnScreen(ref);

    return  (
        <div className="video-text-section" >
            <FadeIn>
                <div className="video-text-inner container container-padding">
                    <div className="header-container">
                        <h3 className="header-sm">Increase conversions with an online personal shopper</h3>

                        <p>Decisions, decisions. ‘I don’t know where to start’, ‘Which product is right for me?’, ‘Who can I ask?’ Online shopping can be complex, overwhelming, and can often lead to abandonment due to confusion.</p>

                        <p>Imagine if your site had an online personal shopper, always there and always available to assist your customers. Nothing pushy, just the option to go through some simple questions so our state-of-the-art AI monster can offer relevant and incredibly helpful advice (‘Shopif-AI’, anyone? No? Just us then. Awks).</p>

                        <p>Your customers are walked through a series of text or visual cues, and by understanding their needs and preferences, they are presented with personalised recommendations which can lead to increased sales, higher AOV and happier customers. </p>

                        <p>Super-simple to integrate as an add-on function to new or existing sites to assist your customers journey and increase that all important conversion rate.</p>

                        <Link to="/contact" className="plus-btn">Request a Demo <div className="icon"></div></Link>
                    </div>

                    <div className="content-container" ref={ref} >
                        <PhoneVideo playing={isVisible}/>
                    </div>
                </div>
            </FadeIn>
        </div>
    );
};

export default VideoText;