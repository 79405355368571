import React, { useContext, useEffect } from 'react';
import { Store } from '../../Store';
import { useLaxElement } from 'use-lax';

import './WorkSingleBlockquote.scss';

export default function WorkSingleBlockquote({ id, text, citation, textColor }) {
    const { dispatch } = useContext(Store);

    const blockquoteRef = useLaxElement();

    useEffect(() => {
        function sectionHeaderColor() {
            if (textColor === 'dark' && blockquoteRef.current && window.scrollY >= blockquoteRef.current.offsetTop && window.scrollY < blockquoteRef.current.offsetTop + blockquoteRef.current.clientHeight) {
                dispatch({ type: 'SET_WHITE_HEADER', payload: false });
            }
        }

        window.addEventListener('scroll', sectionHeaderColor);

        return (() => {
            window.removeEventListener('scroll', sectionHeaderColor);
        });
    }, [textColor, blockquoteRef, dispatch]);

    return (
        <div ref={blockquoteRef} id={id} className={`work-blockquote container-padding ${textColor}-text`} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
            <blockquote className="wp-block-quote">
                <div dangerouslySetInnerHTML={{ __html: text }}></div>

                { citation ? <cite>{citation}</cite> : null }
            </blockquote>
        </div>
    )
}
