import React, {useRef, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './EcommerceStats.scss';
import AnimatedNumber from "animated-number-react";
import ProgressBar from "../ProgressBar/ProgressBar";
import FadeIn from "../Animations/FadeIn";
import { InView } from 'react-intersection-observer';
import {Link} from "react-router-dom";


function Stats(){
    const ref = useRef();

    const [isVisible, setVisible] = useState(false);
    const [val1] = useState(22);

        return (
            <div style={{backgroundColor: '#414042'}}>
                <div className="container container-padding ecommerce-stats">
                    <InView as="div"  style={{width: '100%'}} onChange={(inView, entry) => setVisible(inView)}>
                        <FadeIn>
                            <div className="ecommerce-stats-inner">
                                <div className="col1">
                                    <div>
                                        <h3 className="stats-header">The stats say it all</h3>
                                    </div>

                                    <div className="stat-1" ref={ref}>
                                        <div className="stat-number">
                                            <AnimatedNumber
                                                value={!isVisible ? 0 : val1}
                                                duration={2000}
                                                formatValue={(value) => value.toFixed(0)}
                                            />
                                        </div>

                                        <div className="stat-percent">
                                            %
                                        </div>
                                    </div>

                                    <div className="large-percent-text">
                                        Increase in average order value
                                    </div>
                                </div>

                                <div className="col2">
                                    <div>
                                        <ProgressBar
                                            value={!isVisible ? 0 : 42}
                                            text='Uplift in sales of accessories'/>
                                    </div>

                                    <div>
                                        <ProgressBar
                                            value={!isVisible ? 0 : 54}
                                            text='Improvement in customer satisfaction '/>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <Link to="/contact" className="plus-btn">Request a Demo <div className="icon"></div></Link>
                            </div>
                        </FadeIn>
                    </InView>
                </div>
            </div>
        );

}

export default Stats;