import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { Store } from '../../Store';
import { CSSTransition } from 'react-transition-group';
import { useLaxElement } from 'use-lax';
import AnimateCC from 'react-adobe-animate';
import Script from 'react-load-script';

import api from '../../api/api';

import Error404 from '../Error404/Error404';

import './CareersSingle.scss';

function CareerSingleContent({ career }) {
    const contentRef = useLaxElement();

    const locations = career.locations ? career.locations : false;
    const job_types = career.job_types ? career.job_types : false;

    document.title = career.yoast_head_json.og_title ? career.yoast_head_json.og_title : 'Careers - Creative Monster';

    document.querySelector('meta[name="description"]').setAttribute("content", career.yoast_head_json.og_description ? career.yoast_head_json.og_description : '');

    return (
        <section ref={contentRef} className="container container-padding" data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
            <div className="career-single-details">
                <div className="career-single-info">
                    { job_types || locations ? 
                        <div className="meta">
                            { job_types ? 
                                <div><strong>Type:</strong> {job_types.map((job_type, index) => `${job_type.name}${index !== job_types.length - 1 ? ', ' : '' }`)}</div> 
                            : null }
                            { locations ? 
                                <div><strong>Location:</strong> {locations.map((location, index) => `${location.name}${index !== locations.length - 1 ? ', ' : '' }`)}</div> 
                            : null }
                        </div>
                    : null }
                    <Link to="/careers/" className="plus-btn">Back<div className="icon"></div></Link>
                </div>
                <div className="career-single-text">
                    <h1 className="header header-sm">{ career.title.rendered }</h1>
                    <div dangerouslySetInnerHTML={{ __html: career.content.rendered }}></div>

                    { career.application_link ?
                        <div><a href={ career.application_link } className="plus-btn" target="_blank">Apply Now <div className="icon"></div></a></div>
                    : null }

                    <Link to="/contact/" className="plus-btn">Contact Us <div className="icon"></div></Link>
                </div>
            </div>
        </section>
    );
}

export default function CareersSingle() {
    const { state, dispatch } = useContext(Store);
    const { services } = state;
    const { careerSlug } = useParams();
    const careerSingleBannerRef = useRef();
    const [{ data, loading, error }] = useAxios(`${api.careers}?slug=${careerSlug}`);

    const [ career, setCareer ] = useState(false);
    const [ serviceAnimationLoaded, setServiceAnimationLoaded ] = useState(false);
    const [ serviceAnimationName, setServiceAnimationName ] = useState(false);
    const [ serviceAnimationScript, setServiceAnimationScript ] = useState(false);
    const [ serviceBackgroundColor, setServiceBackgroundColor ] = useState(false);

    useEffect(() => {
        function setWhiteHeader() {
            if (!careerSingleBannerRef.current)
                return;

            if (window.scrollY >= careerSingleBannerRef.current.offsetTop && window.scrollY <= careerSingleBannerRef.current.offsetTop + careerSingleBannerRef.current.clientHeight) {
                dispatch({ type: 'SET_WHITE_HEADER', payload: true });
            } else {
                dispatch({ type: 'SET_WHITE_HEADER', payload: false });
            }
        };
            
        if (!loading && !error && data.length)
            setWhiteHeader();

        const service = services.find((e) => e.title === career.service);

        if (service) {
            setServiceAnimationName(service.animationName);
            setServiceAnimationScript(service.animationScript);
            setServiceBackgroundColor(service.backgroundColor);
        }

        if (data && data.length) {
            setCareer(data[0]);
        }

        window.addEventListener('scroll', setWhiteHeader);
        window.addEventListener('resize', setWhiteHeader);
    
        return () => {
            dispatch({ type: 'SET_WHITE_HEADER', payload: false });
            
            window.removeEventListener('scroll', setWhiteHeader);
            window.removeEventListener('resize', setWhiteHeader);
        }
    }, [dispatch, careerSingleBannerRef, loading, error, data, setCareer, career, services, setServiceAnimationName, setServiceAnimationScript, setServiceBackgroundColor]);

    if (loading || error || !career)
        return <div className="full-screen"></div>;

    if (!data.length)
        return <Error404 />

    return (
        <CSSTransition in={true} appear={true} timeout={500} classNames="loaded" unmountOnExit>
            <main className="career-single-content">
                <article className="career-single">
                    <section ref={ careerSingleBannerRef } className="career-single-banner center-section" style={{ backgroundColor: serviceBackgroundColor }}>
                        <div className="career-single-banner-inner container container-padding">
                            <h1 className="header header-md">{ career.title.rendered }</h1>
                            <div className="animation-wrap">
                                { serviceAnimationLoaded ? <AnimateCC animationName={serviceAnimationName} /> : null }
                            </div>
                        </div>
                    </section>

                    <CareerSingleContent career={career} />
                </article>

                { serviceAnimationName && serviceAnimationScript ?
                    <Script
                    url={serviceAnimationScript}
                    onLoad={() => { if (!serviceAnimationLoaded) setServiceAnimationLoaded(true) }} />
                : null }
            </main>
        </CSSTransition>
    );
}
