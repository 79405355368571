// let API_BASE = '/admin';
let API_BASE = 'https://phpstack-238280-4410839.cloudwaysapps.com/admin';

if (process.env.NODE_ENV !== 'production') {
    API_BASE = 'https://phpstack-238280-4410839.cloudwaysapps.com/admin';
}

export default {
    'work': `${API_BASE}/wp-json/wp/v2/work`,
    'workCategories': `${API_BASE}/wp-json/wp/v2/work_category`,
    'news': `${API_BASE}/wp-json/wp/v2/posts`,
    'adminPost': `${API_BASE}/wp-admin/admin-post.php`,
    'careers': `${API_BASE}/wp-json/wp/v2/career`,
    'services': `${API_BASE}/wp-json/wp/v2/services`,
    'options': `${API_BASE}/wp-json/acf/v3/options/options`,
}
