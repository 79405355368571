import React, { useState } from 'react';
import { useLaxElement } from 'use-lax';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import './HomeClients.scss';

import samsungLogo from '../../assets/client-logos/samsung.svg';
import howdooLogo from '../../assets/client-logos/howdoo.svg';
import itbLogo from '../../assets/client-logos/itb.svg';
// import nokiaLogo from '../../assets/client-logos/nokia.svg';
import novartisLogo from '../../assets/client-logos/novartis.svg';
import warnerLogo from '../../assets/client-logos/warner.svg';
import rosewoodLogo from '../../assets/client-logos/rosewood.svg';
import futureGenerationLogo from '../../assets/client-logos/future-generation.svg';
import condeNastLogo from '../../assets/client-logos/conde-nast.svg';
import gqLogo from '../../assets/client-logos/gq.svg';
import vogueLogo from '../../assets/client-logos/vogue.svg';
import techdataLogo from '../../assets/client-logos/techdata.svg';
import citrixLogo from '../../assets/client-logos/citrix.svg';
import sapLogo from '../../assets/client-logos/sap.svg';
// import lenovoLogo from '../../assets/client-logos/lenovo.svg';
import fruktLogo from '../../assets/client-logos/frukt.svg';
import sasLogo from '../../assets/client-logos/sas.svg';
import oracleLogo from '../../assets/client-logos/oracle.svg';

export default function HomeClients() {
    const [ clients ] = useState([
        { name: 'Samsung', logo: samsungLogo },
        { name: 'Howdoo', logo: howdooLogo },
        { name: 'ITB', logo: itbLogo },
        { name: 'Frukt', logo: fruktLogo },
        { name: 'Novartis', logo: novartisLogo },
        { name: 'Warner', logo: warnerLogo },
        { name: 'Rosewood', logo: rosewoodLogo },
        { name: 'Future Generation', logo: futureGenerationLogo },
        { name: 'Condé Nast', logo: condeNastLogo },
        { name: 'GQ', logo: gqLogo },
        { name: 'Vogue', logo: vogueLogo },
        { name: 'TechData', logo: techdataLogo },
        { name: 'Oracle', logo:  oracleLogo },
        { name: 'Citrix', logo: citrixLogo },
        { name: 'SAP', logo: sapLogo },
        { name: 'SAS', logo: sasLogo }
    ]);

    const containerRef = useLaxElement();
    
    return (
        <section className="home-clients container container-padding">
            <div ref={containerRef} className="home-clients-inner" data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
                { clients ? clients.map((client, index) => 
                    <div key={index}>
                        <LazyLoadImage src={client.logo} 
                            alt={client.name}
                            effect="opacity" />
                    </div>
                ) : null }
            </div>
        </section>
    );
}
