import React from 'react';
import './Platforms.scss';

import FadeIn from "../Animations/FadeIn";
import shopifyLogo from '../../assets/images/logos/shopify.svg';
import wooLogo from '../../assets/images/logos/woocommerce.svg';
import magentoLogo from '../../assets/images/logos/magento.svg';
import {Link} from "react-router-dom";

export default function Platforms() {
    return (
        <div className="platforms-section">
            <FadeIn>
                <div className="platforms-inner container container-padding">
                <div className="platforms-text">
                    <div className="header-container">
                        <h3 className="header-sm">Simple and powerful e-commerce platforms fuelled by monster magic</h3>
                    </div>

                    <div className="content-container">
                        <p>There are lots of ecommerce platforms available to choose from, and of course we have our favourites, but we’ll always work with you to ensure the chosen platform will offer you all the features that you need. </p>

                        <p>Shopify is an obvious big name and with good reason, but we can walk you through the differences with other options like WooCommerce, BigCommerce and Magento to ensure we start the process with the right decision. </p>

                        <p>Whichever route we choose together, your ecommerce site will be rich with features that you’ll need - from content management and SEO, to reporting and email marketing integration.</p>

                        <p>Want to know more?</p>

                        <Link to="/contact" className="plus-btn">Contact Us <div className="icon"></div></Link>
                    </div>
                </div>

                <div className="platforms-logos">
                    <div className="platform-logo">
                        <div><img src={shopifyLogo} alt=""/></div>
                    </div>

                    <div className="platform-logo">
                        <div><img src={wooLogo} alt=""/></div>
                    </div>

                    <div className="platform-logo">
                        <div><img src={magentoLogo} alt=""/></div>
                    </div>
                </div>
            </div>
            </FadeIn>
        </div>
    );
}
