import React, { useContext, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Store } from './Store';
import { resizeOpenEls } from './Helpers';
import { CSSTransition } from 'react-transition-group';
import Analytics from 'react-router-ga';
import lax from 'lax.js';
import { hotjar } from 'react-hotjar';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import PreLoader from './components/PreLoader/PreLoader';
import SiteHeader from './components/SiteHeader/SiteHeader';
import SiteFooter from './components/SiteFooter/SiteFooter';
import Home from './components/Home/Home';
import Work from './components/Work/Work';
import WorkSingle from './components/WorkSingle/WorkSingle';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Ecommerce from './components/Ecommerce/Ecommerce';
import Careers from './components/Careers/Careers';
import CareersSingle from './components/CareersSingle/CareersSingle';
import News from './components/News/News';
import NewsSingle from './components/NewsSingle/NewsSingle';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Error404 from './components/Error404/Error404';
import ServiceSingle from './components/ServiceSingle/ServiceSingle';
import CookiePolicy from './components/CookiePolicy/CookiePolicy';

const routes = [
	{ path: '/', name: 'Home', Component: Home },
	{ path: ['/work/', '/work/page/:page'], name: 'Work', Component: Work },
	{ path: '/work/:workSlug/', name: 'Work', Component: WorkSingle },
	{ path: '/contact/', name: 'Contact', Component: Contact },
	{ path: '/about/', name: 'About', Component: About },
	{ path: ['/careers/', '/careers/page/:page'], name: 'Careers', Component: Careers },
	{ path: '/career/:careerSlug/', name: 'Career', Component: CareersSingle },
	{ path: ['/news', '/news/page/:page/'], name: 'News', Component: News },
	{ path: '/news/:newsSlug/', name: 'News', Component: NewsSingle },
	{ path: '/ecommerce', name: 'News', Component: Ecommerce },
	{ path: '/privacy-policy/', name: 'Privacy Policy', Component: PrivacyPolicy },
	{ path: '/cookie-policy/', name: 'Cookie Policy', Component: CookiePolicy }
];

const redirects = [
	{ from: '/work/rosewood/', to: '/work/rosewood-mini-wishes/', status: '301' },
	{ from: '/work/chesamel/', to: '/', status: '301' },
	{ from: '/work/catch/', to: '/', status: '301' },
	{ from: '/work/nourish/', to: '/', status: '301' },
	{ from: '/work/ati/', to: '/', status: '301' }
];





function RedirectWithStatus({ from, to, status }) {
	return (
		<Route
			render={({ staticContext }) => {
				// there is no `staticContext` on the client, so
				// we need to guard against that here
				if (staticContext) staticContext.status = status;
				return <Redirect from={from} to={to} />;
			}}
		/>
	);
}

export default function App() {
	const { state, dispatch } = useContext(Store);
	const { services } = state;
	var requestRef = useRef();



	hotjar.initialize(2533765, 6);

	const servicePaths = services && services.length ? services.map(service => {
		return service.path
	}) : false;

	useEffect(() => {
		let winWidth = window.innerWidth;

		lax.setup();

		const updateLax = () => {
			lax.updateElements();
			lax.update(window.scrollY);
			requestRef.current = window.requestAnimationFrame(updateLax);
		};

		requestRef.current = window.requestAnimationFrame(updateLax);

		function updateDimensions() {
			const currWinWidth = window.innerWidth;

			if (winWidth !== currWinWidth) {
				const smFullScreenHeight = winWidth < 768 ? 0 : document.documentElement.clientHeight;

				dispatch({ type: 'SET_FULL_SCREEN_HEIGHT', payload: document.documentElement.clientHeight });
				dispatch({ type: 'SET_SM_FULL_SCREEN_HEIGHT', payload: smFullScreenHeight });

				winWidth = currWinWidth
			}
		};

		setTimeout(() => {
			dispatch({ type: 'SET_PAGE_LOADED', payload: true });
		}, 2500);

		window.addEventListener('resize', updateDimensions.bind(this));
		window.addEventListener('resize', resizeOpenEls.bind(this));
		window.addEventListener('resize', () => lax.updateElements());

		return () => {
			window.removeEventListener('resize', updateDimensions.bind(this));
			window.removeEventListener('resize', resizeOpenEls.bind(this));
			window.removeEventListener('resize', () => lax.updateElements());

			if (requestRef.current) {
				window.cancelAnimationFrame(requestRef.current);
			}
		};

	}, [dispatch]);

	return (
		<Router>
			<ScrollToTop />

			<SiteHeader />

			<Analytics id="UA-5276554-3">
				<Switch>
					{redirects ? redirects.map(({ from, to, status }, index) => (
						<RedirectWithStatus key={index} status={status} from={from} to={to} />
					)) : null}

					{routes ? routes.map(({ path, Component }) => (
						<Route key={path} exact path={path}>
							{({ match }) => (
								<CSSTransition in={match != null} appear={true} timeout={{ enter: 500 }} classNames="fade" unmountOnExit>
									<Component />
								</CSSTransition>
							)}
						</Route>
					)) : null}

					{servicePaths ?
						<Route exact path={servicePaths}>
							{({ match }) => (
								<CSSTransition in={match != null} appear={true} timeout={{ enter: 500 }} classNames="fade" unmountOnExit>
									<ServiceSingle />
								</CSSTransition>
							)}
						</Route>
						: null}

					<Route path="*">
						{({ match }) => (
							<CSSTransition in={match != null} appear={true} timeout={{ enter: 500 }} classNames="fade" unmountOnExit>
								<Error404 />
							</CSSTransition>
						)}
					</Route>
				</Switch>
			</Analytics>

			<SiteFooter />

			<PreLoader />
		</Router>
	);
}
