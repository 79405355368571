import React, { useEffect, useContext, useState, useRef } from 'react';
import AnimateCC from 'react-adobe-animate';
import { Store } from '../../Store';
import Script from 'react-load-script';

import './HomeBanner.scss';

import homeBannerMonsterTruckClouds from '../../assets/images/home-banner-monster-truck-clouds.png';
//import mp4Video from '../../assets/videos/christmas-2021.mp4';

function RockBanner() {
    const { state } = useContext(Store);
    const { fullScreenHeight } = state;

    const homeBannerRef = useRef();
    const homeBannerInnerRef = useRef();
    const [lightBottomPosition, setLightBottomPosition] = useState('100%');
    const [ampsLeftLoaded, setAmpsLeftLoaded] = useState(false);
    const [ampsRightLoaded, setAmpsRightLoaded] = useState(false);
    const [monsterRockSoloLoaded, setMonsterRockSoloLoaded] = useState(false);

    useEffect(() => {
        function setHomeBannerInnerBottomPos() {
            if (!homeBannerRef.current || !homeBannerInnerRef.current)
                return false;

            var homeBannerInnerBottomPos = homeBannerRef.current.clientHeight - (homeBannerInnerRef.current.offsetTop + homeBannerInnerRef.current.clientHeight);

            setLightBottomPosition(homeBannerInnerBottomPos);
        }

        setHomeBannerInnerBottomPos();

        window.addEventListener('resize', setHomeBannerInnerBottomPos);

        return () => window.removeEventListener('resize', setHomeBannerInnerBottomPos);
    }, []);

    return (
        <div ref={homeBannerRef} className="home-banner full-screen rock-banner" style={{ minHeight: fullScreenHeight }}>
            <div ref={homeBannerInnerRef} className="home-banner-inner full-screen-inner">
                <div>
                    <h1 className="header-lg container container-padding">
                        Creative <br />
                        Agency
                    </h1>
                    <div className="monster-rock-stage">
                        <div className="monster-rock-amps-left animation-wrap">
                            {ampsLeftLoaded ? <AnimateCC animationName="AmpsLeft" /> : null}
                        </div>
                        <div className="monster-rock-solo animation-wrap">
                            {monsterRockSoloLoaded ? <AnimateCC animationName="MonsterRockSolo" /> : null}
                        </div>
                        <div className="monster-rock-amps-right animation-wrap">
                            {ampsRightLoaded ? <AnimateCC animationName="AmpsRight" /> : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="light" style={{ bottom: lightBottomPosition }}>
                <div></div>
            </div>

            <Script
                url="/amps-left-animation.js"
                onLoad={() => { if (!ampsLeftLoaded) setAmpsLeftLoaded(true) }} />

            <Script
                url="/monster-rock-animation.js"
                onLoad={() => { if (!monsterRockSoloLoaded) setMonsterRockSoloLoaded(true) }} />

            <Script
                url="/amps-right-animation.js"
                onLoad={() => { if (!ampsRightLoaded) setAmpsRightLoaded(true) }} />


            <div className="scroller-wrapper">
                <div>
                    <div className="scroller">
                        <span></span>
                    </div>
                    <div className="scroller-text">SCROLL</div>
                </div>
            </div>
        </div>
    );
}

function MonsterTruckBanner() {
    const { state, dispatch } = useContext(Store);
    const { fullScreenHeight } = state;

    const whiteHeader = useRef();

    const [monsterTruckLoaded, setMonsterTruckLoaded] = useState(false);
    const [monsterTruckTurtleLoaded, setMonsterTruckTurtleLoaded] = useState(false);
    const [treeLeftLoaded, setTreeLeftLoaded] = useState(false);
    const [treeRightLoaded, setTreeRightLoaded] = useState(false);

    useEffect(() => {
        function setWhiteHeader() {
            if (!whiteHeader.current)
                return;

            if (window.scrollY >= whiteHeader.current.offsetTop && window.scrollY <= whiteHeader.current.offsetTop + whiteHeader.current.clientHeight) {
                dispatch({ type: 'SET_WHITE_HEADER', payload: true });
            } else {
                dispatch({ type: 'SET_WHITE_HEADER', payload: false });
            }
        };

        setWhiteHeader();

        window.addEventListener('scroll', setWhiteHeader);
        window.addEventListener('resize', setWhiteHeader);

        return () => {
            window.removeEventListener('scroll', setWhiteHeader);
            window.removeEventListener('resize', setWhiteHeader);
            dispatch({ type: 'SET_WHITE_HEADER', payload: false });
        }
    }, [dispatch]);

    return (
        <div ref={whiteHeader} className="home-banner full-screen monster-truck-banner" style={{ minHeight: fullScreenHeight }}>
            <div className="home-banner-inner full-screen-inner">
                <div>
                    <h1 className="header-lg container container-padding">
                        Creative <br />
                        Agency
                    </h1>
                    {homeBannerMonsterTruckClouds ? <img src={homeBannerMonsterTruckClouds} alt="" className="clouds" /> : null}
                    <div className="monster-truck-stage">
                        <div className="monster-truck-tree-left animation-wrap">
                            {treeLeftLoaded ? <AnimateCC animationName="MonsterTruckTreeLeft" /> : null}
                        </div>
                        <div className="monster-truck animation-wrap">
                            {monsterTruckLoaded ? <AnimateCC animationName="MonsterTruck" /> : null}
                        </div>
                        <div className="monster-truck-turtle animation-wrap">
                            {monsterTruckTurtleLoaded ? <AnimateCC animationName="MonsterTruckTurtle" /> : null}
                        </div>
                        <div className="monster-truck-tree-right animation-wrap">
                            {treeRightLoaded ? <AnimateCC animationName="MonsterTruckTreeRight" /> : null}
                        </div>
                    </div>
                </div>
            </div>

            <Script
                url="/monster-truck-animation.js"
                onLoad={() => { if (!monsterTruckLoaded) setMonsterTruckLoaded(true) }} />

            <Script
                url="/monster-truck-turtle-animation.js"
                onLoad={() => { if (!monsterTruckTurtleLoaded) setMonsterTruckTurtleLoaded(true) }} />

            <Script
                url="/monster-truck-tree-left-animation.js"
                onLoad={() => { if (!treeLeftLoaded) setTreeLeftLoaded(true) }} />

            <Script
                url="/monster-truck-tree-right-animation.js"
                onLoad={() => { if (!treeRightLoaded) setTreeRightLoaded(true) }} />

            <div className="scroller-wrapper">
                <div>
                    <div className="scroller">
                        <span></span>
                    </div>
                    <div className="scroller-text">SCROLL</div>
                </div>
            </div>
        </div>
    );
}

function SpaceBanner() {
    const { state, dispatch } = useContext(Store);
    const { fullScreenHeight } = state;

    const whiteHeader = useRef();

    const [astroMonsterLoaded, setAstroMonsterLoaded] = useState(false);
    const [planetLoaded, setPlanetLoaded] = useState(false);

    useEffect(() => {
        function setWhiteHeader() {
            if (!whiteHeader.current)
                return;

            if (window.scrollY >= whiteHeader.current.offsetTop && window.scrollY <= whiteHeader.current.offsetTop + whiteHeader.current.clientHeight) {
                dispatch({ type: 'SET_WHITE_HEADER', payload: true });
            } else {
                dispatch({ type: 'SET_WHITE_HEADER', payload: false });
            }
        };

        setWhiteHeader();

        window.addEventListener('scroll', setWhiteHeader);
        window.addEventListener('resize', setWhiteHeader);

        return () => {
            window.removeEventListener('scroll', setWhiteHeader);
            window.removeEventListener('resize', setWhiteHeader);
            dispatch({ type: 'SET_WHITE_HEADER', payload: false });
        }
    }, [dispatch]);

    return (
        <div ref={whiteHeader} className="home-banner full-screen space-banner" style={{ minHeight: fullScreenHeight }}>
            <div className="home-banner-inner full-screen-inner">
                <div>
                    <h1 className="header-lg container container-padding">
                        Creative <br />
                        Agency
                    </h1>
                    <div className="space-stage">
                        <div className="planet animation-wrap">
                            {planetLoaded ? <AnimateCC animationName="Planet" /> : null}
                        </div>
                        <div className="astro-monster animation-wrap">
                            {astroMonsterLoaded ? <AnimateCC animationName="AstroMonster" /> : null}
                        </div>
                    </div>
                </div>
            </div>

            <Script
                url="/planet-animation.js"
                onLoad={() => { if (!planetLoaded) setPlanetLoaded(true) }} />

            <Script
                url="/astro-monster-animation.js"
                onLoad={() => { if (!astroMonsterLoaded) setAstroMonsterLoaded(true) }} />

            <div className="scroller-wrapper">
                <div>
                    <div className="scroller">
                        <span></span>
                    </div>
                    <div className="scroller-text">SCROLL</div>
                </div>
            </div>
        </div>
    );
}

// function Mp4Banner() {
//     const { state, dispatch } = useContext(Store);

//     const whiteHeader = useRef();

//     useEffect(() => {
//         function setWhiteHeader() {
//             if (!whiteHeader.current)
//                 return;

//             if (window.scrollY >= whiteHeader.current.offsetTop && window.scrollY <= whiteHeader.current.offsetTop + whiteHeader.current.clientHeight) {
//                 dispatch({ type: 'SET_WHITE_HEADER', payload: true });
//             } else {
//                 dispatch({ type: 'SET_WHITE_HEADER', payload: false });
//             }
//         };

//         setWhiteHeader();

//         window.addEventListener('scroll', setWhiteHeader);
//         window.addEventListener('resize', setWhiteHeader);

//         return () => {
//             window.removeEventListener('scroll', setWhiteHeader);
//             window.removeEventListener('resize', setWhiteHeader);
//             dispatch({ type: 'SET_WHITE_HEADER', payload: false });
//         }
//     }, [dispatch]);

//     return (
//         <div ref={whiteHeader}>
//             <div className="hero-video-wrapper home-banner" style={{ backgroundColor: '#e8d6d6' }}>
//                 <video autoplay="true" muted="true" playsinline="true" webkit-playsinline="true" loop="true">
//                     <source src={mp4Video} type="video/mp4" />
//                 </video>

//                 <div className="scroller-wrapper">
//                     <div>
//                         <div className="scroller">
//                             <span></span>
//                         </div>
//                         <div className="scroller-text">SCROLL</div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

const banners = [
    <RockBanner />,
    <MonsterTruckBanner />,
    <SpaceBanner />
];

const activeBanner = banners[Math.floor(Math.random() * banners.length)];

export default function HomeBanner() {
    return (
        <section>
            {activeBanner}
        </section>
    );
}

//TEMP MP4

// export default function HomeBanner() {
//     return (
//         <section>
//             <Mp4Banner />
//         </section>
//     );
// }


