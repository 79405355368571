import React, { useState, useEffect, useContext } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Store } from '../../Store';

import axios from 'axios';

import AboutVideo from '../AboutVideo/AboutVideo';

import aboutIntro1Video from '../../assets/videos/about-intro-1.mp4';
import aboutIntro2Video from '../../assets/videos/about-intro-2.mp4';
import aboutStep1Video from '../../assets/videos/about-step-1.mp4';
import aboutStep2Video from '../../assets/videos/about-step-2.mp4';
import aboutStep3Video from '../../assets/videos/about-step-3.mp4';
import aboutStep4Video from '../../assets/videos/about-step-4.mp4';
import aboutStep5Video from '../../assets/videos/about-step-5.mp4';
import aboutStep6Video from '../../assets/videos/about-step-6.mp4';
import aboutStep7Video from '../../assets/videos/about-step-7.mp4';

import './About.scss';
import AboutText from '../AboutText/AboutText';

const steps = [
    {
        id: 1,
        stepText: 'Intro',
        showHeader: true,
        content: `<p>Hey - thank goodness you found us! You know, there’s been a heap of legends and tall tales about our creative monsters. All different too.</p>`,
        button: `START`,
        backgroundColor: '#f5a70c',
        textColor: 'white',
        mainVideo: aboutIntro1Video
    },
    {
        id: 2,
        stepText: 'Intro',
        content: `<p>Well, we, folks, are about to tell you the real version. Here’s the story of what really happened that fateful night. So sit back, relax and let us tell it like it is… Or was, or whatever.</p>`,
        button: `NEXT`,
        backgroundColor: '#f5a70c',
        textColor: 'white',
        mainVideo: aboutIntro2Video
    },
    {
        id: 3,
        stepText: '1/7',
        content: `<p>Our story begins way back when. <br />T’was late one night, probably at least half past ten.</p><p>A young designer was at his wits end, <br />as he struggled to create a design that was on trend.</p>`,
        button: `NEXT`,
        backgroundColor: '#272b56',
        textColor: 'white',
        mainVideo: aboutStep1Video
    },
    {
        id: 4,
        stepText: '2/7',
        content: `<p>When all of a sudden a light shone bright and clear, <br />and in his desk draw a monster did appear.</p><p>Although it gave him such a fright, <br />the monster seemed friendly and rather polite.</p>`,
        button: `NEXT`,
        backgroundColor: '#14235f',
        textColor: 'white',
        mainVideo: aboutStep2Video
    },
    {
        id: 5,
        stepText: '3/7',
        content: `<p>So he simply asked with uncertainty and intrigue <br />how he got here and could he help with his current need?</p><p>The monster explained he was from far far away. <br />A parallel universe where creative monsters did play.</p>`,
        button: `NEXT`,
        backgroundColor: '#19171a',
        textColor: 'white',
        mainVideo: aboutStep3Video
    },
    {
        id: 6,
        stepText: '4/7',
        content: `<p>Monstropolis it was called, no place for a mere mortal. <br />Yet their worlds were connected via a magic portal.</p><p>But "why was he here?" our bewildered designer cried. <br />"That’s easy, to create something spectacular" the monster replied.</p>`,
        button: `NEXT`,
        backgroundColor: '#7e4c9e',
        textColor: 'white',
        mainVideo: aboutStep4Video
    },
    {
        id: 7,
        stepText: '5/7',
        content: `<p>So together they worked hard late into the night. <br />Applying monster magic they created something that would delight.</p><p>Then the very next day the new design was presented. <br />The client was thrilled, it was exactly as they intended.</p>`,
        button: `NEXT`,
        backgroundColor: '#1a0c4c',
        textColor: 'white',
        timeoutColors: 'dark-grey',
        timeoutHeaderColor: 'dark',
        timeoutBackgroundColor: '#fdf8f6',
        timeoutDelay: 7000,
        mainVideo: aboutStep5Video
    },
    {
        id: 8,
        stepText: '6/7',
        content: `<p>It was from that day forth they worked out a plan. <br />Let’s form a creative agency, and with that, Creative Monster began.</p><p>Over the years word spread and their creativity grew &amp; grew. <br />So did the team and in turn the monsters did too.</p>`,
        button: `NEXT`,
        backgroundColor: '#fdf8f6',
        textColor: 'dark-grey',
        mainVideo: aboutStep6Video
    },
    {
        id: 9,
        stepText: '7/7',
        content: `<p>This is our exciting story, even if somewhat bizarre. <br />It’s filled with wonder and furry friends from afar.</p><p>We invite you to join us and be part of the magic. <br />Having you there will make our monsters ecstatic.</p>`,
        link: '/contact',
        linkText: 'Contact Us',
        backgroundColor: '#f5a70c',
        textColor: 'white',
        mainVideo: aboutStep7Video
    }
];

export default function About() {
    const { dispatch } = useContext(Store);
    const [ activeStep, setActiveStep ] = useState(0);
    const [ backgroundColor, setBackgroundColor ] = useState();
    const [ textColor, setTextColor ] = useState(steps[activeStep].textColor);
    const [ muteVideo, setMuteVideo ] = useState(false);

    function getSeo() {
        axios.get('https://phpstack-238280-4410839.cloudwaysapps.com/admin/wp-json/yoast/v1/get_head?url=' + window.location.href).then(res => {
            const seo = res.data;

            if (seo) {
                document.title = seo.json.og_title ? seo.json.og_title : 'Careers - Creative Monster';
                document.querySelector('meta[name="description"]').setAttribute("content", seo.json.og_description ? seo.json.og_description : '');
            }
        }).catch(function(e) {

        })
    }

    useEffect(() => {
        getSeo();

        dispatch({ type: 'SET_WHITE_HEADER', payload: true });

        return () => dispatch({ type: 'SET_WHITE_HEADER', payload: false });
        // eslint-disable-next-line
    }, []);

    function nextStep() {
        const nextStep = activeStep + 1 < steps.length ? activeStep + 1 : false;

        if (nextStep)
            setActiveStep(nextStep);
    }

    function previousStep() {
        const previousStep = activeStep - 1 > 0 ? activeStep - 1 : 0;

        setActiveStep(previousStep);
    }

    return (
        <main className="about-content" style={{ backgroundColor }}>
            <article className="about">
                <div className="about-inner">
                    <div className="about-container container container-padding">
                        <div className={`about-step ${textColor}`}>
                            <h1 className={`header header-lg ${!steps[activeStep].showHeader ? 'hide': ''}`}>About</h1>

                            <TransitionGroup className="about-text">
                                <CSSTransition key={activeStep} in={true} appear={true} timeout={{ enter: 500 }} classNames="fade">
                                    <AboutText stepIndex={activeStep} stepText={steps[activeStep].stepText} button={steps[activeStep].button} link={steps[activeStep].link} linkText={steps[activeStep].linkText} backgroundColor={steps[activeStep].backgroundColor} textColor={steps[activeStep].textColor} timeoutColors={steps[activeStep].timeoutColors} timeoutHeaderColor={steps[activeStep].timeoutHeaderColor} timeoutBackgroundColor={steps[activeStep].timeoutBackgroundColor} timeoutDelay={steps[activeStep].timeoutDelay} setTextColor={setTextColor} nextStep={nextStep} previousStep={previousStep} muteVideo={muteVideo} setMuteVideo={setMuteVideo}>
                                        {steps[activeStep].content}
                                    </AboutText>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                    </div>

                    <TransitionGroup>
                        <CSSTransition key={activeStep} in={true} appear={true} timeout={{ enter: 1000, exit: 1000 }} classNames="fade">
                            <AboutVideo mainVideo={steps[activeStep].mainVideo} nextStep={nextStep} backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} muteVideo={muteVideo} />
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </article>
        </main>
    )
}
