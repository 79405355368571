import React from 'react';
import { Link } from 'react-router-dom';
import useAxios from 'axios-hooks';
import Slider from 'react-slick';
import { useLaxElement } from 'use-lax';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import api from '../../api/api';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import './HomeWork.scss';

import chevronLeftIcon from '../../assets/icons/chevron-left-icon.svg';
import chevronRightIcon from '../../assets/icons/chevron-right-icon.svg';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-prev slick-arrow" +
            (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
    >
        Previous
    </button>
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
    >
        Next
    </button>
);

const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
        {
            breakpoint: 520,
            settings: {
                centerPadding: '0',
                slidesToShow: 1,
            }
        }
    ]
};

export default function HomeWork() {
    const [{ data }] = useAxios(`${api.work}?_embed&wpse_custom_order=menu_order&order=asc&per_page=12`);

    const headerRef = useLaxElement();
    const sliderWrapRef = useLaxElement();
    const viewAllRef = useLaxElement();

    return (
        <div className="home-work">
            <h2 ref={headerRef} className="container container-padding header-lg" data-lax-anchor="self" data-lax-translate-x="(vh*0.75) 30, (-elh) -30" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">Work</h2>

            <div ref={sliderWrapRef} data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
                { data ? 
                    <LazyLoadComponent>
                        <Slider {...sliderSettings} className="slider">
                            { data.map(work => 
                                <div key={ work.id }>
                                    <article className="slide">
                                        <Link to={`/work/${work.slug}` } className="slider-inner">
                                            <div className="icon"></div>
                                            <div className="slide-text">
                                                <div className="slide-text-inner">
                                                    { work.title.rendered }
                                                </div>

                                                <div className="arrows">
                                                    <img src={chevronRightIcon} alt="" className="arrow-right" />
                                                    <img src={chevronLeftIcon} alt="" className="arrow-left" />
                                                </div>
                                            </div>
                                            { work.featured_media ? 
                                                <img src={work._embedded['wp:featuredmedia'][0].media_details.sizes.square_lg.source_url} 
                                                    alt={work.title.rendered} 
                                                    className="background" /> 
                                            : null }
                                        </Link>
                                    </article>
                                </div>
                            ) }
                        </Slider>
                    </LazyLoadComponent>
                : null }
            </div>

            <div ref={viewAllRef} className="home-work-btn container container-padding" data-lax-anchor="self" data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1">
                <Link to="/work" className="plus-btn"><div>View all work</div><div className="icon"></div></Link>
            </div>
        </div>
    )
}
