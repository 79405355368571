import React from 'react';
import { useLaxElement } from 'use-lax';


export default function FadeInAnimation(props) {
    const fadeIn = useLaxElement();

    return (
        <div ref={fadeIn}
             data-lax-anchor="self"
             data-lax-opacity="(vh*0.75) 0, (vh*0.5) 1"
                style={{width: '100%'}}>

            {props.children}

        </div>
    );
}
