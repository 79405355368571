import React from 'react';
import ReactPlayer from 'react-player';
import './PhoneVideo.scss';
import phoneVideo from '../../assets/videos/phone-video.MP4';
import Reveal from 'react-reveal/Reveal';


function PhoneVideo(props) {

    return (
        <div className="phone-video-container">
            <Reveal effect="fade-in-image" offset="-2500px">
                <div className="phone-video">
                    <div className="iphone-border"></div>

                    <ReactPlayer url={ phoneVideo}
                                 className="phone-video-inner"
                                 muted={true}
                                 width="auto" height="80vh"
                                 loop={ true }
                                 playing={ props.playing  }
                                 controls={ false }/>
                </div>
            </Reveal>
        </div>
    );
};

export default PhoneVideo;